import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "./apiConfig";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../styles/componentsScss/Search.scss";
import "../styles/componentsScss/Overlay.scss";

function Search({ toggleNav }) {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  const [searchData, setSearchData] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [showInput, setShowInput] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const toggleInput = () => {
    setShowInput(!showInput);
    if (!showInput) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  const handleInputChange = (e) => {
    setSearchKey(e.target.value);
  };
  const searchHandler = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/search?q=${searchKey}`)
      .then((res) => {
        console.log("Response data:", res.data); // Log the full response for debugging

        // Check if the response indicates success and the data array has items
        const hasData =
          res.data.success && res.data.data && res.data.data.length > 0;

        // Check if the searchKey is contained within the body or title of the data array's items
        const containsSearchKey =
          hasData &&
          res.data.data.some(
            (item) =>
              (item.body &&
                item.body.toLowerCase().includes(searchKey.toLowerCase())) ||
              (item.title &&
                item.title.toLowerCase().includes(searchKey.toLowerCase()))
          );

        if (containsSearchKey) {
          // If the searchKey is found in the data, set the data and navigate to the news page
          setSearchData(res.data.data);
          toggleInput();
          setSearchKey("");
          navigate(
            urlPage === "en"
              ? `/en/search-display/${searchKey}`
              : `/search-display/${searchKey}`
          );
        } else {
          // If searchKey is not found, navigate to the not-found page
          navigate("/not-found");
          toggleInput();
        }
      })
      .catch((error) => {
        console.error("Error searching:", error);
      });
  };

  return (
    <div className={urlPage === "en" ? "searchIocnEn" : "searchIocn"}>
      <Link onClick={toggleInput}>
        <FontAwesomeIcon icon={faSearch} className="Search-Icons" />
      </Link>
      {showInput && (
        <div className="overlay">
          <form onSubmit={searchHandler}>
            <button type="button" onClick={toggleInput}>
              <svg
                width="100"
                height="100"
                viewBox="0 0 114 114"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: "10%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textDecoration: "none",
                  zIndex: 1,
                }}
              >
                <path
                  d="M42.4258 70.7109L70.7101 42.4267"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M70.7101 70.7101L42.4258 42.4258"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div className="input-wrapper">
              <input
                ref={inputRef}
                name="search"
                placeholder={t("Search Here")}
                value={searchKey}
                onChange={handleInputChange}
              />
              <button
                type="submit"
                className={
                  urlPage === "en" ? "search-buttonEn" : "search-button"
                }
                onClick={toggleNav}
              >
                {t("Search")}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Search;
