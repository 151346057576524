import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Main.scss";
import ExploreMoreButton from "./ExploreMoreButton";
import { useTranslation } from "react-i18next";

function HeaderStyle() {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  const historyAR =
    "/en" + window.location.pathname.split("/").slice(0).join("/");
  // console.log(window.location.pathname)
  // console.log(historyAR)
  const historyEN =
    "/" + window.location.pathname.split("/").slice(2).join("/");
  // console.log(historyEN)

  if (urlPage == "en") {
    return (
      <>
        {/* <div >
        <Link to={historyEN}>
          <button type="submit"
            style={{
              border: '1px solid white',
              justifyContent: 'center',
              padding: ' 4px 40px',
              borderRadius: '8px',
              color: ' white',
              fontSize: '20px',
              backgroundColor: '#061041'
            }}

          >

            {t('AR')}

          </button>
        </Link>

      </div> */}

        <ExploreMoreButton
          buutonText="AR"
          style="mainButtonLightStyle"
          link={historyEN}
        />
      </>
    );
  } else {
    return (
      <>
        {/* <div >
        <Link to={historyAR}>
          <button type="submit"
            style={{
              border: '1px solid white',
              justifyContent: 'center',
              padding: ' 4px 40px',
              borderRadius: '8px',
              color: ' white',
              fontSize: '20px',
              backgroundColor: '#061041'
            }}

          >

            {t('EN')}

          </button>
        </Link>

      </div> */}
        <ExploreMoreButton
          buutonText="EN"
          style="mainButtonLightStyle"
          link={historyAR}
        />
      </>
    );
  }
}
const ChangeLang = (props) => {
  return (
    <Fragment>
      {HeaderStyle()}

      <br />
      <br />
      <br />
      <br />
    </Fragment>
  );
};

export default ChangeLang;
