import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultHeader from "../components/DefaultHeader";
import { Col, Container, Row } from "react-bootstrap";
import HeaderImg from "../pages/Imgs/SSU.png";

function TechnicalTiket() {
  const [t, i18n] = useTranslation();

  const [formData, setFormData] = useState({
    email: "",
    description: "",
    specialization: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.createElement("form");
    form.action =
      "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
    form.method = "POST";

    const fields = {
      orgid: "00D4L000000i6vU",
      retURL: "https://www.eelu.edu.eg/thank-you",
      email: formData.email,
      type: "Request",
      recordType: "012R5000001VqwD",
      "00N4L000003swUg": formData.specialization,
      description: formData.description,
      status: "New",
    };

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Fragment>
      <DefaultHeader
        title={t("Technical Support For Employee")}
        backgroundImage={HeaderImg}
      />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={12} md={12}>
                <br />
                <div style={{ color: "rgb(17, 38, 113)" }}>
                  {t("Technical Support For Employee")}
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={4}>
                    <label htmlFor="email">{t("University Email")}</label>
                    <input
                      id="email"
                      maxLength="80"
                      name="email"
                      size="20"
                      type="text"
                      // placeholder="xxxxxx@.eelu.edu.eg"
                      className="form-control"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <label htmlFor="00N4L000003swUg">
                      {t("Specialization")}
                    </label>

                    <select
                      id="00N4L000003swUg"
                      name="specialization" // Ensure the name matches the state field
                      title="التخصص"
                      onChange={handleChange}
                      value={formData.specialization} // Bind the value to the state
                      required
                    >
                      <option value="">--None--</option>
                      <option value="Network">Network</option>
                      <option value="Devices">Devices</option>
                      <option value="Systems">Systems</option>
                    </select>
                    <br></br>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <label htmlFor="description">
                      {t("Description Your Complaint")}
                    </label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                    ></textarea>
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <button
                      type="submit"
                      className="btn btn-info"
                      style={{
                        backgroundColor: " rgb(17 35 97)",
                        justifyContent: "center",
                        padding: " 8px 24px",
                        borderRadius: "8px",
                        color: " #f4f5fc",
                        fontSize: "20px",
                        border: "0px",
                        marginTop: "2%",
                        marginBottom: "3%",
                      }}
                    >
                      {t("Submit")}
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default TechnicalTiket;
