import React, { Fragment } from "react";
import "../styles/Main.scss";

function buttonStyle(props) {
  if (props.style == "exploreMoreStyle") {
    return (
      <>
        <button
          className="exploreMore"
          style={{ target: "_blank" }}
          onClick={
            props.link == undefined
              ? ""
              : (event) => window.open(`${props.link}`)
          }
        >
          {props.buutonText}

          <svg
            className="svgIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="16.194"
            height="12.267"
            viewBox="0 0 16.194 12.267"
          >
            <path
              id="Vector"
              d="M6.133,0a.747.747,0,0,1,.482.169.538.538,0,0,1,.2.409V14.219l4.287-3.64a.771.771,0,0,1,.965,0,.519.519,0,0,1,0,.819L6.616,16.024a.7.7,0,0,1-.221.126.794.794,0,0,1-.522,0,.7.7,0,0,1-.221-.126L.2,11.4a.519.519,0,0,1,0-.819.748.748,0,0,1,.482-.17.748.748,0,0,1,.482.17l4.287,3.64V.578a.538.538,0,0,1,.2-.409A.747.747,0,0,1,6.133,0Z"
              transform="translate(16.194 0) rotate(90)"
            />
          </svg>
        </button>
      </>
    );
  } else if (props.style == "exploreMoreStyleDark") {
    document.documentElement.style.setProperty(
      "--selectedColor",
      'map-get($colorSystem , "grayColor" )'
    );

    return (
      <>
        <button
          className="exploreMoreDark"
          onClick={
            props.link == undefined
              ? ""
              : (event) => (window.location.href = `${props.link}`)
          }
        >
          {props.buutonText}
          <svg
            sty
            className="svgIcon"
            xmlns="http://www.w3.org/2000/svg"
            width="16.194"
            height="12.267"
            viewBox="0 0 16.194 12.267"
          >
            <path
              id="Vector"
              d="M6.133,0a.747.747,0,0,1,.482.169.538.538,0,0,1,.2.409V14.219l4.287-3.64a.771.771,0,0,1,.965,0,.519.519,0,0,1,0,.819L6.616,16.024a.7.7,0,0,1-.221.126.794.794,0,0,1-.522,0,.7.7,0,0,1-.221-.126L.2,11.4a.519.519,0,0,1,0-.819.748.748,0,0,1,.482-.17.748.748,0,0,1,.482.17l4.287,3.64V.578a.538.538,0,0,1,.2-.409A.747.747,0,0,1,6.133,0Z"
              transform="translate(16.194 0) rotate(90)"
            />
          </svg>
        </button>
      </>
    );
  } else if (props.style == "mainButtonStyle") {
    return (
      <button
        className="mainButton"
        onClick={
          props.link == undefined
            ? ""
            : (event) => (window.location.href = `${props.link}`)
        }
      >
        {props.buutonText}
      </button>
    );
  } else if (props.style == "mainButtonLightStyle") {
    return (
      <button
        className="mainButtonLight"
        onClick={
          props.link == undefined
            ? ""
            : (event) => (window.location.href = `${props.link}`)
        }
      >
        {props.buutonText}
        <svg
          className="svglang"
          style={{ marginTop: "-8%" }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99935 18.3334C14.6017 18.3334 18.3327 14.6025 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66667 2.5H7.5C5.875 7.36667 5.875 12.6333 7.5 17.5H6.66667"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.5 2.5C14.125 7.36667 14.125 12.6333 12.5 17.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.5 13.3333V12.5C7.36667 14.125 12.6333 14.125 17.5 12.5V13.3333"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.5 7.5C7.36667 5.875 12.6333 5.875 17.5 7.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    );
  } else if (props.style == "mainButtonDarkStyle") {
    return (
      <button
        className="mainButtonDark"
        onClick={
          props.link == undefined
            ? ""
            : (event) => (window.location.href = `${props.link}`)
        }
      >
        {props.buutonText}
      </button>
    );
  }
}
const ExploreMoreButton = (props) => {
  return <Fragment>{buttonStyle(props)}</Fragment>;
};
export default ExploreMoreButton;
