function Policy() {
    return (


        <p>
            Privacy Policy
            EELU takes your privacy seriously. To better protect your privacy, we provide this privacy policy notice explaining how your personal information is collected and used.


            Collection of Routine Information
            This website can track IP address and Location for analytics purposes, but does not track basic information about its visitors, such as browser details, timestamps, or referring pages.
            We do not collect or store personal information related to visitors or users.


            Cookies
            This website does not use cookies to store information about visitors' preferences or history. We do not use cookies for tracking purposes.


            Advertisement and Other Third Parties
            We do not use third-party advertising partners or other third parties to track visitors' activities on this website. As such, there are no cookies, scripts, or web beacons used for advertisement purposes.


            Links to Third Party Websites
            We have included links on this website for your use and reference. We are not responsible for the privacy policies on these websites. You should be aware that the privacy policies of these websites may differ from our own.


            Security
            The security of your personal information is important to us, While we strive to use commercially acceptable means to protect your personal information.
            Changes To This Privacy Policy
            This Privacy Policy is effective as of [24/07/2024] and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.


            We reserve the rights to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically.
            If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us or by placing a prominent notice on our website.


            Contact Information
            For any questions or concerns regarding the privacy policy, please send us an email to [info@eelu.edu.eg] or contact us by phone at [16541].
            You can also visit EELU Campus at 33 Al Messaha street, Ad Dokki A, Dokki, Giza Governorate 12611.
        </p>
    );
}
export default Policy;