import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Main.scss";
import { useTranslation } from 'react-i18next';

function MoreButton(props) {
    const [t, i18n] = useTranslation();
    const url = window.location.href.split("/");
    const urlPage = url[3];
    return (
        <a
            href={props.link ? props.link : "#"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
        >
            <button
                type="submit"
                className={props.style === "MoreDark" ? "buttonMoreDark" : "buttonMoreLight"}
                onClick={props.link == undefined ? () => { } : event => window.location.href = `${props.link}`}
            >
                {t('More')}
            </button>
        </a>

    );
}
export default MoreButton