import React, { useState } from 'react'
import { ArrowRight2 } from "../Icons/ArrowRight2/ArrowRight2";
import axios from 'axios';
import { useEffect } from 'react';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { BASE_URL } from './apiConfig';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../styles/componentsScss/SSU-Form.scss"


function SsuFormCard(props) {


    const [isSelected, setSelectedCard] = useState(false);
    const [childSelected, setChildSelected] = useState(false);


    // const handleCardClick = (id) => {
    //     setSelectedCard(!isSelected);
    // };
    const { selected } = props



    const apiToggle = props.callBackFn
    // const handleClicking = () => {
    //     if (!isSelected) {
    //         apiToggle()

    //     }
    // }
    const handleClicking = () => {
        apiToggle()
        const halfScrollHeight = document.body.scrollHeight / 2;
        window.scrollTo({ top: halfScrollHeight, behavior: 'smooth' });

    }
    useEffect(() => {
        // console.log(props.icon)
    }, [])
    return (

        <div className="col1" >
            <div
                className="card h-100 ssucard"


                style={{
                    borderRadius: '15px',
                    borderBottom: '5px solid #E68900',
                    opacity: selected ? '1' : '0.5',
                    cursor: 'pointer'
                    // backgroundColor: 'white',
                }}

            >

                <div className="card-body" onClick={handleClicking}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}>

                    <div className="group_form">
                        <div style={{


                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>

                            <LazyLoadImage

                                src={`${BASE_URL}/icons/${props.icon}`}
                                style={{

                                    display: 'block',

                                }}
                                alt='..'
                            />
                            <br></br>

                            <div style={{
                                textAlign: 'center',
                                fontSize: '13px',
                            }} >{props.title}</div>


                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.56995 5.93005L3.49995 12.0001L9.56995 18.0701" stroke="#157F40" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20.5 12H3.67" stroke="#157F40" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                    </div>

                </div>


            </div>

        </div >
    )
}

export default SsuFormCard