import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/componentsScss/ContactUs.scss"
import { useTranslation } from 'react-i18next';
import DefaultHeader from "../components/DefaultHeader";
import contactt from '../pages/Imgs/contact.png';

function Contact() {
    const [t, i18n] = useTranslation();
    const currentYear = new Date().getFullYear();

    const url = window.location.href.split("/");
    const urlPage = url[3];
    const [isFocused1, setFocused1] = useState(false);
    const [isFocused2, setFocused2] = useState(false);
    const [isFocused3, setFocused3] = useState(false);
    const [isFocused4, setFocused4] = useState(false);

    return (
        <Fragment>
            <DefaultHeader title={t('Contact Us')}
                backgroundImage={contactt} />
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div className="frame-container-wrapper">
                <div className="frame" >
                    <div className="" >

                        <div className="text-wrapper" style={{ textAlign: urlPage === 'en' ? "left" : 'right' }}> {t('Contact Information')}</div>
                        <Row>
                            <Col className="FooterCol" style={{ marginTop: '10%', width: '50%', lineHeight: '20px' }}  >
                                <p style={{ fontSize: '16px', marginTop: '-4%', color: 'white' }}>
                                    {t("Al Ahliya University's contacts and address\n\n Egyptian E-Learning in Egypt.")}</p>

                            </Col>
                        </Row>

                        <div className="div-3">
                            <div className="div-4">
                                <Row>
                                    <Col xs={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                            <path d="M21 9.6C21 13.7888 18.708 17.1472 16.299 19.515C15.1024 20.6912 13.9024 21.5967 13.0012 22.2079C12.5956 22.483 12.2525 22.6971 12 22.8483C11.7475 22.6971 11.4044 22.483 10.9988 22.2079C10.0976 21.5967 8.89762 20.6912 7.70099 19.515C5.292 17.1472 3 13.7888 3 9.6C3 7.33262 3.93787 5.14942 5.62146 3.53316C7.30645 1.91557 9.60018 1 12 1C14.3998 1 16.6935 1.91557 18.3785 3.53316C20.0621 5.14942 21 7.33262 21 9.6Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M15.6654 9.99967C15.6654 10.9721 15.2791 11.9048 14.5914 12.5924C13.9038 13.28 12.9712 13.6663 11.9987 13.6663C11.0262 13.6663 10.0936 13.28 9.40597 12.5924C8.71834 11.9048 8.33203 10.9721 8.33203 9.99967C8.33203 9.02721 8.71834 8.09458 9.40597 7.40695C10.0936 6.71932 11.0262 6.33301 11.9987 6.33301C12.9712 6.33301 13.9038 6.71932 14.5914 7.40695C15.2791 8.09458 15.6654 9.02721 15.6654 9.99967Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </Col>
                                    <Col xs={{ span: 9 }} md={{ span: 9 }} lg={{ span: 10 }} style={{ padding: "0px 25px 0px 18px" }}>
                                        <Link className="FooterInfo" to="" style={{ fontSize: '18px', lineHeight: "24px" }}>
                                            {t('Location')}
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                            <div className="div-5">
                                <Row>
                                    <Col xs={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }} >
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20 9.46154H4V21.3077H20V9.46154ZM1.6 7.76923H22.4V2.69231H1.6V7.76923ZM21.6 9.46154V22.1538C21.6 22.3783 21.5157 22.5935 21.3657 22.7522C21.2157 22.9109 21.0122 23 20.8 23H3.2C2.98783 23 2.78434 22.9109 2.63431 22.7522C2.48429 22.5935 2.4 22.3783 2.4 22.1538V9.46154H0.8C0.587827 9.46154 0.384344 9.37239 0.234315 9.21371C0.0842854 9.05502 0 8.8398 0 8.61538V1.84615C0 1.62174 0.0842854 1.40652 0.234315 1.24783C0.384344 1.08915 0.587827 1 0.8 1H23.2C23.4122 1 23.6157 1.08915 23.7657 1.24783C23.9157 1.40652 24 1.62174 24 1.84615V8.61538C24 8.8398 23.9157 9.05502 23.7657 9.21371C23.6157 9.37239 23.4122 9.46154 23.2 9.46154H21.6ZM9.6 12.8462H14.4C14.6122 12.8462 14.8157 12.9353 14.9657 13.094C15.1157 13.2527 15.2 13.4679 15.2 13.6923C15.2 13.9167 15.1157 14.1319 14.9657 14.2906C14.8157 14.4493 14.6122 14.5385 14.4 14.5385H9.6C9.38783 14.5385 9.18434 14.4493 9.03431 14.2906C8.88429 14.1319 8.8 13.9167 8.8 13.6923C8.8 13.4679 8.88429 13.2527 9.03431 13.094C9.18434 12.9353 9.38783 12.8462 9.6 12.8462V12.8462Z" fill="white" />
                                        </svg>



                                    </Col>
                                    <Col xs={{ span: 9 }} md={{ span: 9 }} lg={{ span: 10 }} style={{ padding: "0px 25px 0px 18px" }}>

                                        <Link className="FooterInfo" to="" style={{ fontSize: '18px' }}>
                                            {t('Fax')}
                                        </Link></Col>
                                </Row>
                            </div>
                            <div className="div-6">
                                <Row>
                                    <Col xs={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 2C2 15.6 8.4 22 22 22V15.6L16.4 14L14.8 16.4C11.6 16.4 7.6 12.4 7.6 9.2L10 7.6L8.4 2H2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>



                                    </Col>
                                    <Col xs={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} style={{ padding: "0px 25px 0px 18px" }}>

                                        <Link className="FooterInfo" to="" style={{ fontSize: '18px' }}>
                                            {t('Telephone1')}
                                        </Link></Col>
                                </Row>                     </div>
                            <div className="div-5">
                                <Row>
                                    <Col xs={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }} >
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.5972 10.8827L19.4 8.04075V3.66865L15.7314 0H5.8V6.4H2.2C1.88185 6.40037 1.57684 6.52692 1.35188 6.75188C1.12692 6.97684 1.00037 7.28185 1 7.6V22.8C1.00037 23.1181 1.12692 23.4232 1.35188 23.6481C1.57684 23.8731 1.88185 23.9996 2.2 24H23V11.7797C22.9999 11.6102 22.964 11.4427 22.8946 11.2881C22.8252 11.1335 22.7238 10.9953 22.5972 10.8827ZM5.8 22.4H2.6V8H5.8V22.4ZM14.6 1.6H15.0686L17.8 4.33135V4.8H14.6V1.6ZM7.4 1.6H13V6.4H17.8V10H7.4V1.6ZM21.4 22.4H7.4V11.6H19.4V10.1814L21.4 11.9593V22.4Z" fill="white" />
                                            <path d="M8.79688 16.4002H10.3969V18.0002H8.79688V16.4002ZM11.9969 16.4002H13.5969V18.0002H11.9969V16.4002ZM8.79688 19.6002H10.3969V21.2002H8.79688V19.6002ZM11.9969 19.6002H13.5969V21.2002H11.9969V19.6002ZM15.1969 16.4002H16.7969V18.0002H15.1969V16.4002ZM15.1969 19.6002H16.7969V21.2002H15.1969V19.6002ZM8.79688 13.2002H16.7969V14.8002H8.79688V13.2002Z" fill="white" />
                                        </svg>



                                    </Col>
                                    <Col xs={{ span: 9 }} md={{ span: 9 }} lg={{ span: 10 }} style={{ padding: "0px 25px 0px 18px" }}>

                                        <Link className="FooterInfo" to="" style={{ fontSize: '18px' }}>
                                            {t('Telephone2')}
                                        </Link></Col>
                                </Row>                  </div>
                            <div className="div-5">
                                <Row>
                                    <Col xs={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24H18V21.6H12C6.792 21.6 2.4 17.208 2.4 12C2.4 6.792 6.792 2.4 12 2.4C17.208 2.4 21.6 6.792 21.6 12V13.716C21.6 14.664 20.748 15.6 19.8 15.6C18.852 15.6 18 14.664 18 13.716V12C18 8.688 15.312 6 12 6C8.688 6 6 8.688 6 12C6 15.312 8.688 18 12 18C13.656 18 15.168 17.328 16.248 16.236C17.028 17.304 18.372 18 19.8 18C22.164 18 24 16.08 24 13.716V12C24 5.376 18.624 0 12 0ZM12 15.6C10.008 15.6 8.4 13.992 8.4 12C8.4 10.008 10.008 8.4 12 8.4C13.992 8.4 15.6 10.008 15.6 12C15.6 13.992 13.992 15.6 12 15.6Z" fill="white" />
                                        </svg>



                                    </Col>
                                    <Col xs={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }} style={{ padding: "0px 25px 0px 18px" }}>

                                        <Link className="FooterInfo" to="" style={{ fontSize: '18px' }}>
                                            {t('Mail')}
                                        </Link></Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div className="container" style={{ marginRight: '2%', marginTop: '3%' }}>
                    <form style={{
                        position: 'relative',
                        marginBottom: '20px',
                        display: 'inline-block'
                    }}>
                        <p style={{ fontSize: '25px', marginTop: '-2%', color: '#061041', fontWeight: '700', }}>
                            {t("Contact Form")}</p>
                        <div className="row conform" >
                            <div className="col-md-6 mb-3" >
                                <label for="" className="form-label hisname" style={{ top: '-5%', left: urlPage === 'en' ? '5%' : '' }}>
                                    {t("User Name")} </label>
                                <input
                                    type="text" id="subject2"
                                    placeholder={t('Write your Name Here...')}
                                    style={{
                                        border: '1px solid ',
                                        boxShadow: 'none',
                                        borderColor: isFocused1 ? "#09154E" : "#8C8C8C",
                                        backgroundColor: 'transparent',
                                        width: '80%',
                                        borderRadius: '6px'

                                    }}

                                    onFocus={() => setFocused1(true)}
                                    onBlur={() => setFocused1(false)}

                                ></input>

                            </div>
                            <div className="col-md-6 mb-3 mailclass" >
                                <label for="" className="form-label hisname" style={{ top: '-5%', left: urlPage === 'en' ? '5%' : '' }}>{t("Email")} </label>
                                <input type="email" id="" aria-describedby="emailHelp"
                                    placeholder={t('Write your Email here...')}
                                    style={{
                                        border: '1px solid ',
                                        boxShadow: 'none',
                                        borderColor: isFocused2 ? "#09154E" : "#8C8C8C",
                                        width: '80%',
                                        borderRadius: '6px',
                                        backgroundColor: 'transparent',



                                    }}
                                    onFocus={() => setFocused2(true)}
                                    onBlur={() => setFocused2(false)}

                                />
                            </div>
                            <div className=" mb-3 topic" >
                                <label for="" className="form-label2" style={{ left: urlPage === 'en' ? '2%' : '' }}>
                                    {t('The Topic')}  </label>
                                <input type="text" className="form-control" id=""
                                    placeholder={t('Type the title of the subject here...')}
                                    style={{
                                        boxShadow: 'none',
                                        borderColor: isFocused3 ? "#09154E" : "#8C8C8C",
                                        backgroundColor: "transparent",
                                        width: '80%'
                                    }}

                                    onFocus={() => setFocused3(true)}
                                    onBlur={() => setFocused3(false)}

                                />
                            </div>
                            <div className=" mb-3 topic" >
                                <label for="exampleInput" className="form-label1" style={{ top: '-5%', left: urlPage === 'en' ? '2%' : '' }} >{t("Message")}</label>

                                <textarea className="form-control"
                                    placeholder={t('Write the letter here...')} id="exampleInput" onFocus={() => setFocused4(true)}
                                    onBlur={() => setFocused4(false)}
                                    style={{
                                        border: `1px solid `,
                                        borderColor: isFocused4 ? "#09154E" : "#8C8C8C",
                                        borderRadius: '6px',
                                        backgroundColor: "transparent",
                                        height: "100px",
                                        width: '80%'
                                    }}
                                ></textarea>
                            </div>
                        </div>
                        <br></br>
                        <Link to="https://sis.eelu.edu.eg/static/index.html" >
                            <button type="submit" className="btnn"
                            >
                                {t('Send')}

                            </button>
                        </Link>            </form>
                </div>
            </div >

            < br />
            <br />
            <br /></Fragment >
    );
}
export default Contact