import { Fragment, useEffect, useState } from "react";
import "../styles/componentsScss/Advs.scss";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Home from "../pages/Home";
import { BASE_URL } from "./apiConfig";
import axios from "axios";
import admission from "../pages/Imgs/ads.jpeg";

function Advertiser() {
  const [showAlert, setShowAlert] = useState(false);
  const [news, setNews] = useState({});
  const url = window.location.href.split("/");
  const urlPage = url[3];

  const handleCloseAlert = () => {
    setShowAlert(false);
    sessionStorage.setItem("alertClosed", "true"); // Remember that the alert was closed
  };

  useEffect(() => {
    // Check if the alert has been closed in this session
    const alertClosed = sessionStorage.getItem("alertClosed");
    if (!alertClosed) {
      axios
        .get(`${BASE_URL}/news/lastNews`)
        .then((res) => {
          setNews(res.data.data);
          if (res.data.data.active) {
            setShowAlert(true);
            // console.log(res.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
          // Handle error and possibly setShowAlert(false) if needed
        });
    }
  }, []);
  return (
    <Fragment>
      <div>
        {showAlert && (
          <div id="thanksalert">
            <Home />
            <div className="overlay">
              <div
                className="alert-wrapper"
                style={{
                  position: "fixed",
                  top: "0",
                  left: "32px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: "9999",
                }}
              >
                <div
                  className="alert-card"
                  style={
                    {
                      // backgroundColor: 'white',
                      // boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    }
                  }
                >
                  <div style={{ position: "relative" }}>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        top: "-25px",
                        left: "50%", // Align horizontally in the center
                        transform: "translateX(-50%)", // Center horizontally
                        textDecoration: "none",
                        zIndex: 1,
                      }}
                      onClick={handleCloseAlert}
                    >
                      <path
                        d="M40.0013 6.66602C21.6346 6.66602 6.66797 21.6322 6.66797 39.9984C6.66797 58.3645 21.6346 73.3307 40.0013 73.3307C58.368 73.3307 73.3346 58.3645 73.3346 39.9984C73.3346 21.6322 58.368 6.66602 40.0013 6.66602ZM51.2013 47.6648C52.168 48.6314 52.168 50.2314 51.2013 51.198C50.7013 51.698 50.068 51.9313 49.4346 51.9313C48.8013 51.9313 48.168 51.698 47.668 51.198L40.0013 43.5316L32.3346 51.198C31.8346 51.698 31.2013 51.9313 30.568 51.9313C29.9346 51.9313 29.3013 51.698 28.8013 51.198C27.8346 50.2314 27.8346 48.6314 28.8013 47.6648L36.468 39.9984L28.8013 32.3319C27.8346 31.3653 27.8346 29.7653 28.8013 28.7987C29.768 27.8321 31.368 27.8321 32.3346 28.7987L40.0013 36.4651L47.668 28.7987C48.6346 27.8321 50.2346 27.8321 51.2013 28.7987C52.168 29.7653 52.168 31.3653 51.2013 32.3319L43.5346 39.9984L51.2013 47.6648Z"
                        fill="#C70707"
                      />
                    </svg>

                    <div
                      className="alert-content"
                      style={{ textAlign: "center" }}
                    >
                      <Link
                        to="https://admission.eelu.edu.eg/"
                        // <Link to={urlPage === 'en' ? `/en/News/${news.params}` : `/News/${news.params}`}
                        state={news}
                        style={{ display: "block", position: "relative" }}
                      >
                        <img
                          src={admission}
                          alt="..."
                          style={{ width: "100%", height: "auto" }}
                        />
                        {/* <img src={`${BASE_URL}/news/${news.imgUrl}`} alt='...' style={{ width: '100%', height: 'auto' }} /> */}
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 2, // Ensure the link is above the image
                            opacity: 0, // Hide the link text
                          }}
                        >
                          {/* Optional: Add a hover effect or something similar */}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!showAlert && <Home />}{" "}
        {/* Render Home component when showAlert is false */}
      </div>
    </Fragment>
  );
}
export default Advertiser;
