import zIndex from "@mui/material/styles/zIndex";
import React, { useEffect, useState } from "react";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import "../styles/Main.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

function ScrollupButton() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const buttonStyle = {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px",
        cursor: "pointer",
        backgroundColor: "#E68900",
        color: "#fff",
        border: "none",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s ease",
        zIndex: 1000,

    };
    return (
        <div style={{ marginRight: "-5%" }}>
            {isVisible && (
                <button onClick={scrollToTop} style={buttonStyle}>
                    <FontAwesomeIcon icon={faAngleUp} style={{ color: "#142B7B" }} />
                    {/* <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="scrollupstyle">
                        <path d="M19.9201 15.0901L13.4001 8.57009C12.6301 7.80009 11.3701 7.80009 10.6001 8.57009L4.08008 15.0901" stroke="#142B7B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> */}

                </button>
            )}
        </div>
    );
}
export default ScrollupButton