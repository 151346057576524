import { Fragment } from "react";
import { Container, Row } from "react-bootstrap";

function PagenotFound() {
  return (
    <Fragment>
      <div className="BodyDiv">
        <Container className="">
          <Row>
            <div className="row row-cols-1 row-cols-md-12 ">
              <h4>لا توجد نتائج في الوقت الحالي </h4>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
export default PagenotFound;
