import React, { Fragment } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../styles/componentsScss/Navbar.scss";
import ChangeLang from "./ChangeLang";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import Search from "./Search";
import { BASE_URL } from "./apiConfig";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
const Navbarr = (props) => {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  const [aboutUni, setUniAbout] = useState(false);
  const [quality, setQuality] = useState(false);
  const [postgraduate, setpostgraduate] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [eventsView, setEventsView] = useState(false);
  const [newsView, setNewsView] = useState(false);
  const [Centers, setCenter] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}/specializedCenters`).then((res) => {
      setCenter(res.data);
      // console.log(res.data)
    });
  }, []);
  // console.log(props)

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    setEventsView(false); // Close events view when toggling nav
  };
  const handleEventsView = () => {
    setEventsView(!eventsView);
    toggleNav();
    // Add additional logic specific to this onClick action
  };

  const handleNewsView = () => {
    setNewsView(!newsView);
    toggleNav();

    // Add additional logic specific to this onClick action
  };

  return (
    <Fragment>
      <div
        className="uppernavbar"
        style={{
          backgroundColor: "#09154E",

          margin: "0",
          padding: "0", // Remove padding
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ul
              style={{
                margin: "10px",
                marginTop: "-16%",
                padding: "0%",
                listStyleType: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              <ChangeLang />
              <li className="nav-item" style={{ display: "flex", gap: "10px" }}>
                <Link
                  to="https://sis.eelu.edu.eg/static/index.html"
                  target="_blank"
                >
                  <button type="submit" className="login-btnHome">
                    {t("Login")}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 14.9 3.25 17.51 5.23 19.34C5.23 19.35 5.23 19.35 5.22 19.36C5.32 19.46 5.44 19.54 5.54 19.63C5.6 19.68 5.65 19.73 5.71 19.77C5.89 19.92 6.09 20.06 6.28 20.2C6.35 20.25 6.41 20.29 6.48 20.34C6.67 20.47 6.87 20.59 7.08 20.7C7.15 20.74 7.23 20.79 7.3 20.83C7.5 20.94 7.71 21.04 7.93 21.13C8.01 21.17 8.09 21.21 8.17 21.24C8.39 21.33 8.61 21.41 8.83 21.48C8.91 21.51 8.99 21.54 9.07 21.56C9.31 21.63 9.55 21.69 9.79 21.75C9.86 21.77 9.93 21.79 10.01 21.8C10.29 21.86 10.57 21.9 10.86 21.93C10.9 21.93 10.94 21.94 10.98 21.95C11.32 21.98 11.66 22 12 22C12.34 22 12.68 21.98 13.01 21.95C13.05 21.95 13.09 21.94 13.13 21.93C13.42 21.9 13.7 21.86 13.98 21.8C14.05 21.79 14.12 21.76 14.2 21.75C14.44 21.69 14.69 21.64 14.92 21.56C15 21.53 15.08 21.5 15.16 21.48C15.38 21.4 15.61 21.33 15.82 21.24C15.9 21.21 15.98 21.17 16.06 21.13C16.27 21.04 16.48 20.94 16.69 20.83C16.77 20.79 16.84 20.74 16.91 20.7C17.11 20.58 17.31 20.47 17.51 20.34C17.58 20.3 17.64 20.25 17.71 20.2C17.91 20.06 18.1 19.92 18.28 19.77C18.34 19.72 18.39 19.67 18.45 19.63C18.56 19.54 18.67 19.45 18.77 19.36C18.77 19.35 18.77 19.35 18.76 19.34C20.75 17.51 22 14.9 22 12ZM16.94 16.97C14.23 15.15 9.79 15.15 7.06 16.97C6.62 17.26 6.26 17.6 5.96 17.97C4.44 16.43 3.5 14.32 3.5 12C3.5 7.31 7.31 3.5 12 3.5C16.69 3.5 20.5 7.31 20.5 12C20.5 14.32 19.56 16.43 18.04 17.97C17.75 17.6 17.38 17.26 16.94 16.97Z"
                        fill="#0B1742"
                      />
                      <path
                        d="M12 6.92993C9.93 6.92993 8.25 8.60993 8.25 10.6799C8.25 12.7099 9.84 14.3599 11.95 14.4199C11.98 14.4199 12.02 14.4199 12.04 14.4199C12.06 14.4199 12.09 14.4199 12.11 14.4199C12.12 14.4199 12.13 14.4199 12.13 14.4199C14.15 14.3499 15.74 12.7099 15.75 10.6799C15.75 8.60993 14.07 6.92993 12 6.92993Z"
                        fill="#0B1742"
                      />
                    </svg>
                  </button>
                </Link>
                {/* <Link to="https://admission.eelu.edu.eg/" target="_blank">
                  <button type="submit" className="login-btnapply">
                    {t("Apply Now")}
                  </button>
                </Link> */}
              </li>
            </ul>
          </div>
          <div style={{ display: "flex" }} className="uppernavbarInfo">
            <Link to="" style={{ color: "white", textDecoration: "none" }}>
              info@eelu.edu.eg
            </Link>
            <Link
              className="contactHome"
              to=""
              style={{ textDecoration: "none" }}
            >
              16<span style={{ color: "#F6A62E" }}>5</span>41
            </Link>
          </div>
        </div>
      </div>
      <nav
        style={{ backgroundColor: "#142B7B" }}
        className={
          urlPage === "en"
            ? "navbarEn navbar navbar-expand-lg navbar-light  "
            : "navbarAr navbar navbar-expand-lg navbar-light "
        }
      >
        <div className="container" style={{ zIndex: "1000" }}>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            style={{
              marginRight: "133px",
              border: "none",
            }}
            onClick={toggleNav}
          >
            {isNavOpen ? (
              <span className="" style={{ fontSize: "30px" }}>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#F6A62E", border: "none" }}
                />
              </span> // "X" icon
            ) : (
              <FontAwesomeIcon icon={faBars} style={{ color: "white" }} />
              // Default icon
            )}
          </button>

          <div
            className={`collapse navbar-collapse${isNavOpen ? " show" : ""}`}
            id="navbarNav"
          >
            <div className={urlPage === "en" ? "d-flexEn" : "d-flex"}>
              <ul
                className={
                  urlPage === "en"
                    ? " navbar-nav navbar-navEn navbar-navEn"
                    : "navbar-nav navbar-navAr navbar-navAr"
                }
                // style={{ alignItems: "center" }}
              >
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to={urlPage === "en" ? "/en" : "/"}
                    style={{ marginLeft: urlPage === "en" ? "2px" : "0px" }}
                    onClick={() => {
                      setUniAbout(!aboutUni);
                      toggleNav();
                    }}
                  >
                    {" "}
                    {t("Menu.Home")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    role="button"
                    onClick={() => setUniAbout(!aboutUni)}
                  >
                    {t("Menu.About")}
                  </Link>
                  {aboutUni && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/about-university"
                              : "/about-university"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onMouseEnter={() => setEventsView(!eventsView)}
                          onMouseLeave={() => setEventsView(!eventsView)}
                          onClick={() => {
                            setEventsView(!eventsView);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M7.99984 1.33268C4.33317 1.33268 1.33317 4.33268 1.33317 7.99935C1.33317 11.666 4.33317 14.666 7.99984 14.666C11.6665 14.666 14.6665 11.666 14.6665 7.99935C14.6665 4.33268 11.6665 1.33268 7.99984 1.33268Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 10.666L8 7.33268"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00342 5.33398L7.99743 5.33398"
                              stroke="#09154E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("History and Facts")}
                          <svg
                            style={{
                              marginRight: "25%",
                              transform:
                                urlPage === "en" ? "scaleX(-1)" : "none",
                              marginLeft: urlPage === "en" ? "28%" : "",
                            }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.3336 11.2584L8.97526 12.9001L11.6503 15.5751C12.2169 16.1334 13.1836 15.7334 13.1836 14.9334L13.1836 9.74173L13.1836 5.06673C13.1836 4.26673 12.2169 3.86673 11.6503 4.43339L7.3336 8.75006C6.64193 9.43339 6.64193 10.5667 7.3336 11.2584Z"
                              fill="#142B7B"
                            />
                          </svg>

                          {eventsView && (
                            <div className="Submenu">
                              <ul
                                className={
                                  urlPage === "en"
                                    ? "dropdown-menuUniEn dropdown-menu"
                                    : "dropdown-menuUniAr dropdown-menu"
                                }
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "/en/about-university/history-of-eelu"
                                        : "/about-university/history-of-eelu"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M14.6668 8.00065C14.6668 11.6807 11.6802 14.6673 8.00016 14.6673C4.32016 14.6673 1.3335 11.6807 1.3335 8.00065C1.3335 4.32065 4.32016 1.33398 8.00016 1.33398C11.6802 1.33398 14.6668 4.32065 14.6668 8.00065Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M10.4734 10.1192L8.40675 8.88586C8.04675 8.67253 7.75342 8.15919 7.75342 7.73919V5.00586"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>

                                    {t("EELU History")}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "2px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "/en/about-university/vision-of-eelu"
                                        : "/about-university/vision-of-eelu"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.9999 13.5138C10.3532 13.5138 12.5466 12.1271 14.0732 9.72714C14.6732 8.78714 14.6732 7.20714 14.0732 6.26714C12.5466 3.86714 10.3532 2.48047 7.9999 2.48047C5.64656 2.48047 3.45323 3.86714 1.92656 6.26714C1.32656 7.20714 1.32656 8.78714 1.92656 9.72714C3.45323 12.1271 5.64656 13.5138 7.9999 13.5138Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>

                                    {t("EELU Vision")}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "2px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "/en/about-university/mission-of-eelu"
                                        : "/about-university/mission-of-eelu"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M14.6668 6.66732V10.0007C14.6668 13.334 13.3335 14.6673 10.0002 14.6673H6.00016C2.66683 14.6673 1.3335 13.334 1.3335 10.0007V6.00065C1.3335 2.66732 2.66683 1.33398 6.00016 1.33398H9.3335"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M14.6668 6.66732H12.0002C10.0002 6.66732 9.3335 6.00065 9.3335 4.00065V1.33398L14.6668 6.66732Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4.6665 8.66602H8.6665"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4.6665 11.334H7.33317"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("EELU Mission")}{" "}
                                  </Link>
                                </li>
                                {/* <li><a className="dropdown-item" href="#" style={{ textAlign: urlPage === 'en' ? "left" : 'right' }}>
                                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                                        <path d="M1.3335 14.666H14.6668" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.5 2.66732V14.6673H9.5V2.66732C9.5 1.93398 9.2 1.33398 8.3 1.33398H7.7C6.8 1.33398 6.5 1.93398 6.5 2.66732Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2 6.66732V14.6673H4.66667V6.66732C4.66667 5.93398 4.4 5.33398 3.6 5.33398H3.06667C2.26667 5.33398 2 5.93398 2 6.66732Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.3335 9.99935V14.666H14.0002V9.99935C14.0002 9.26602 13.7335 8.66602 12.9335 8.66602H12.4002C11.6002 8.66602 11.3335 9.26602 11.3335 9.99935Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                      </svg>

                                      {t('Facts and Figures')}  </a></li> */}
                              </ul>
                            </div>
                          )}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/board-of-trustees"
                              : "/board-of-trustees"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          role="button"
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M7.99984 1.33268C4.33317 1.33268 1.33317 4.33268 1.33317 7.99935C1.33317 11.666 4.33317 14.666 7.99984 14.666C11.6665 14.666 14.6665 11.666 14.6665 7.99935C14.6665 4.33268 11.6665 1.33268 7.99984 1.33268Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 10.666L8 7.33268"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00342 5.33398L7.99743 5.33398"
                              stroke="#09154E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Board Of Trustees")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>

                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/chairman-board-word"
                              : "chairman-board-word"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            whiteSpace: "pre",
                            lineHeight: "15px",
                            backgroundColor: "white",
                          }}
                          role="button"
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M8.00016 10.334C9.4735 10.334 10.6668 9.14065 10.6668 7.66732V4.00065C10.6668 2.52732 9.4735 1.33398 8.00016 1.33398C6.52683 1.33398 5.3335 2.52732 5.3335 4.00065V7.66732C5.3335 9.14065 6.52683 10.334 8.00016 10.334Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M2.8999 6.43359V7.56693C2.8999 10.3803 5.18657 12.6669 7.9999 12.6669C10.8132 12.6669 13.0999 10.3803 13.0999 7.56693V6.43359"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.07324 4.28609C7.67324 4.06609 8.32658 4.06609 8.92658 4.28609"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.4668 5.70086C7.82013 5.60753 8.1868 5.60753 8.54013 5.70086"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 12.666V14.666"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t(
                            "The Chairman of The University\n\nBoard of Treustees word"
                          )}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/university-president-word"
                              : "university-president-word"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                            role="button"
                            onClick={() => setUniAbout(!aboutUni)}
                          >
                            <path
                              d="M8.00016 10.334C9.4735 10.334 10.6668 9.14065 10.6668 7.66732V4.00065C10.6668 2.52732 9.4735 1.33398 8.00016 1.33398C6.52683 1.33398 5.3335 2.52732 5.3335 4.00065V7.66732C5.3335 9.14065 6.52683 10.334 8.00016 10.334Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M2.8999 6.43359V7.56693C2.8999 10.3803 5.18657 12.6669 7.9999 12.6669C10.8132 12.6669 13.0999 10.3803 13.0999 7.56693V6.43359"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.07324 4.28609C7.67324 4.06609 8.32658 4.06609 8.92658 4.28609"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.4668 5.70086C7.82013 5.60753 8.1868 5.60753 8.54013 5.70086"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 12.666V14.666"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("University President’s Word")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>

                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/contact" : "contact"}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14.6468 12.2207C14.6468 12.4607 14.5935 12.7073 14.4802 12.9473C14.3668 13.1873 14.2202 13.414 14.0268 13.6273C13.7002 13.9873 13.3402 14.2473 12.9335 14.414C12.5335 14.5807 12.1002 14.6673 11.6335 14.6673C10.9535 14.6673 10.2268 14.5073 9.46016 14.1807C8.6935 13.854 7.92683 13.414 7.16683 12.8607C6.40016 12.3007 5.6735 11.6807 4.98016 10.994C4.2935 10.3007 3.6735 9.57398 3.12016 8.81398C2.5735 8.05398 2.1335 7.29398 1.8135 6.54065C1.4935 5.78065 1.3335 5.05398 1.3335 4.36065C1.3335 3.90732 1.4135 3.47398 1.5735 3.07398C1.7335 2.66732 1.98683 2.29398 2.34016 1.96065C2.76683 1.54065 3.2335 1.33398 3.72683 1.33398C3.9135 1.33398 4.10016 1.37398 4.26683 1.45398C4.44016 1.53398 4.5935 1.65398 4.7135 1.82732L6.26016 4.00732C6.38016 4.17398 6.46683 4.32732 6.52683 4.47398C6.58683 4.61398 6.62016 4.75398 6.62016 4.88065C6.62016 5.04065 6.5735 5.20065 6.48016 5.35398C6.3935 5.50732 6.26683 5.66732 6.10683 5.82732L5.60016 6.35398C5.52683 6.42732 5.4935 6.51398 5.4935 6.62065C5.4935 6.67398 5.50016 6.72065 5.5135 6.77398C5.5335 6.82732 5.5535 6.86732 5.56683 6.90732C5.68683 7.12732 5.8935 7.41398 6.18683 7.76065C6.48683 8.10732 6.80683 8.46065 7.1535 8.81398C7.5135 9.16732 7.86016 9.49398 8.2135 9.79398C8.56016 10.0873 8.84683 10.2873 9.0735 10.4073C9.10683 10.4207 9.14683 10.4407 9.1935 10.4607C9.24683 10.4807 9.30016 10.4873 9.36016 10.4873C9.4735 10.4873 9.56016 10.4473 9.6335 10.374L10.1402 9.87398C10.3068 9.70732 10.4668 9.58065 10.6202 9.50065C10.7735 9.40732 10.9268 9.36065 11.0935 9.36065C11.2202 9.36065 11.3535 9.38732 11.5002 9.44732C11.6468 9.50732 11.8002 9.59398 11.9668 9.70732L14.1735 11.274C14.3468 11.394 14.4668 11.534 14.5402 11.7007C14.6068 11.8673 14.6468 12.034 14.6468 12.2207Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                            />
                          </svg>
                          {t("Contact Us")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/FAQ" : "/FAQ"}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M12.3136 11.2207L12.5736 13.3273C12.6402 13.8806 12.0469 14.2673 11.5736 13.9806L8.78022 12.3206C8.47356 12.3206 8.17356 12.3007 7.88023 12.2607C8.37356 11.6807 8.66689 10.9473 8.66689 10.154C8.66689 8.26064 7.02689 6.72734 5.00022 6.72734C4.22689 6.72734 3.51356 6.94732 2.92023 7.33398C2.90023 7.16732 2.89355 7.00064 2.89355 6.82731C2.89355 3.79398 5.52689 1.33398 8.78022 1.33398C12.0336 1.33398 14.6669 3.79398 14.6669 6.82731C14.6669 8.62731 13.7402 10.2207 12.3136 11.2207Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.66683 10.1532C8.66683 10.9465 8.3735 11.6799 7.88017 12.2599C7.22017 13.0599 6.1735 13.5732 5.00016 13.5732L3.26016 14.6065C2.96683 14.7865 2.59349 14.5399 2.63349 14.1999L2.80016 12.8866C1.90683 12.2666 1.3335 11.2732 1.3335 10.1532C1.3335 8.97986 1.96017 7.94654 2.92017 7.33321C3.5135 6.94654 4.22683 6.72656 5.00016 6.72656C7.02683 6.72656 8.66683 8.25986 8.66683 10.1532Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("Questions and Answers")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={url}
                    role="button"
                    onClick={() => setUniAbout(!aboutUni)}
                  >
                    {t("Menu.Academic")}
                  </Link>

                  {aboutUni && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/about-faculties"
                              : "/about-faculties"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onMouseEnter={() => setEventsView(!eventsView)}
                          onMouseLeave={() => setEventsView(!eventsView)}
                          onClick={() => {
                            setEventsView(!eventsView);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.3335 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 8.66699V11.3337"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("Facilties")}
                          <svg
                            style={{
                              marginRight: "53%",
                              transform:
                                urlPage === "en" ? "scaleX(-1)" : "none",
                              marginLeft: urlPage === "en" ? "40%" : "",
                            }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.3336 11.2584L8.97526 12.9001L11.6503 15.5751C12.2169 16.1334 13.1836 15.7334 13.1836 14.9334L13.1836 9.74173L13.1836 5.06673C13.1836 4.26673 12.2169 3.86673 11.6503 4.43339L7.3336 8.75006C6.64193 9.43339 6.64193 10.5667 7.3336 11.2584Z"
                              fill="#142B7B"
                            />
                          </svg>
                          {eventsView && (
                            <div className="Submenu">
                              <ul
                                className={
                                  urlPage === "en"
                                    ? "dropdown-menuFacEn dropdown-menu"
                                    : "dropdown-menuFacAr dropdown-menu"
                                }
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "en/about-faculties/faculty-of-computers-and-information-technology"
                                        : "/about-faculties/faculty-of-computers-and-information-technology"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      whiteSpace: "pre",
                                      lineHeight: "15px",
                                      marginLeft: "-10px",
                                      marginBottom: "10px",
                                      backgroundColor: "white",
                                    }}
                                    onMouseEnter={() => setQuality(!quality)}
                                    onMouseLeave={() => setQuality(!quality)}
                                    onClick={() => setQuality(!quality)}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M9.3335 5.33301V8.66634"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M12 5.33301V8.66634"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4 8.66699V11.3337"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t(
                                      "Faculty of Computers and\n\n Information Technology"
                                    )}
                                    <svg
                                      style={{
                                        marginRight: "6%",
                                        transform:
                                          urlPage === "en"
                                            ? "scaleX(-1)"
                                            : "none",
                                        marginLeft:
                                          urlPage === "en" ? "5%" : "",
                                      }}
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.3336 11.2584L8.97526 12.9001L11.6503 15.5751C12.2169 16.1334 13.1836 15.7334 13.1836 14.9334L13.1836 9.74173L13.1836 5.06673C13.1836 4.26673 12.2169 3.86673 11.6503 4.43339L7.3336 8.75006C6.64193 9.43339 6.64193 10.5667 7.3336 11.2584Z"
                                        fill="#142B7B"
                                      />
                                    </svg>
                                    {quality && (
                                      <div className="Submenu">
                                        <ul
                                          className={
                                            urlPage === "en"
                                              ? "dropdown-menQEn dropdown-menu"
                                              : "dropdown-menQAr dropdown-menu"
                                          }
                                        >
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to={
                                                urlPage === "en"
                                                  ? "/en/about-faculties/faculty-of-computers-and-information-technology/fci-quality"
                                                  : "/about-faculties/faculty-of-computers-and-information-technology/fci-quality"
                                              }
                                              style={{
                                                textAlign:
                                                  urlPage === "en"
                                                    ? "left"
                                                    : "right",
                                              }}
                                            >
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                  marginRight:
                                                    urlPage === "en"
                                                      ? "6px"
                                                      : " 0px",
                                                  marginLeft:
                                                    urlPage === "en"
                                                      ? "0px"
                                                      : "5px",
                                                }}
                                              >
                                                <path
                                                  d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M5.99984 6.66667C6.73622 6.66667 7.33317 6.06971 7.33317 5.33333C7.33317 4.59695 6.73622 4 5.99984 4C5.26346 4 4.6665 4.59695 4.6665 5.33333C4.6665 6.06971 5.26346 6.66667 5.99984 6.66667Z"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M1.77979 12.633L5.06645 10.4264C5.59312 10.073 6.35312 10.113 6.82645 10.5197L7.04645 10.713C7.56645 11.1597 8.40645 11.1597 8.92645 10.713L11.6998 8.33305C12.2198 7.88638 13.0598 7.88638 13.5798 8.33305L14.6665 9.26638"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                              {t("Quality")}{" "}
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "2px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "/en/about-faculties/Faculty-of-Business-Administration-and-Commercial-Studies"
                                        : "/about-faculties/Faculty-of-Business-Administration-and-Commercial-Studies"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      whiteSpace: "pre",
                                      lineHeight: "15px",
                                      marginLeft: "-10px",
                                      marginBottom: "10px",
                                      marginTop: "-5px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M9.3335 5.33301V8.66634"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M12 5.33301V8.66634"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4 8.66699V11.3337"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t(
                                      "Faculty of Commerical Studies\n\n and Business Administration"
                                    )}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "5px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "/en/about-faculties/faculty-of-educational-studies"
                                        : "/about-faculties/faculty-of-educational-studies"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      marginTop: "-8px",
                                      backgroundColor: "white",
                                    }}
                                    onMouseEnter={() =>
                                      setpostgraduate(!postgraduate)
                                    }
                                    onMouseLeave={() =>
                                      setpostgraduate(!postgraduate)
                                    }
                                    onClick={() =>
                                      setpostgraduate(!postgraduate)
                                    }
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M9.3335 5.33301V8.66634"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M12 5.33301V8.66634"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4 8.66699V11.3337"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Faculty of Eductional Studies")}
                                    <svg
                                      style={{
                                        marginRight: "6%",
                                        transform:
                                          urlPage === "en"
                                            ? "scaleX(-1)"
                                            : "none",
                                        marginLeft:
                                          urlPage === "en" ? "2%" : "",
                                      }}
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.3336 11.2584L8.97526 12.9001L11.6503 15.5751C12.2169 16.1334 13.1836 15.7334 13.1836 14.9334L13.1836 9.74173L13.1836 5.06673C13.1836 4.26673 12.2169 3.86673 11.6503 4.43339L7.3336 8.75006C6.64193 9.43339 6.64193 10.5667 7.3336 11.2584Z"
                                        fill="#142B7B"
                                      />
                                    </svg>
                                    {postgraduate && (
                                      <div className="Submenu">
                                        <ul
                                          className={
                                            urlPage === "en"
                                              ? "dropdown-menPGEn dropdown-menu"
                                              : "dropdown-menPGAr dropdown-menu"
                                          }
                                        >
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="/about-faculties/faculty-of-educational-studies/postgraduate"
                                              style={{
                                                textAlign:
                                                  urlPage === "en"
                                                    ? "left"
                                                    : "right",
                                                whiteSpace: "pre",
                                                lineHeight: "15px",
                                                marginLeft: "-10px",
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                  marginRight:
                                                    urlPage === "en"
                                                      ? "6px"
                                                      : " 0px",
                                                  marginLeft:
                                                    urlPage === "en"
                                                      ? "0px"
                                                      : "5px",
                                                }}
                                              >
                                                <path
                                                  d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-miterlimit="10"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-miterlimit="10"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M9.3335 5.33301V8.66634"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-miterlimit="10"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M12 5.33301V8.66634"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-miterlimit="10"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-miterlimit="10"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M4 8.66699V11.3337"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-miterlimit="10"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                                                  stroke="#09154E"
                                                  stroke-width="1.5"
                                                  stroke-miterlimit="10"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                              {t("Post Graduate")}
                                              <br />
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    )}{" "}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>

                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/study-system-all"
                              : "/study-system-all"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onMouseEnter={() => setNewsView(!newsView)}
                          onMouseLeave={() => setNewsView(!newsView)}
                          onClick={() => {
                            setNewsView(!newsView);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 8.66699H8.00016"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 11.333H10.6668"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("Study System")}
                          <svg
                            style={{
                              marginRight: "31%",
                              transform:
                                urlPage === "en" ? "scaleX(-1)" : "none",
                              marginLeft: urlPage === "en" ? "18%" : "",
                            }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.3336 11.2584L8.97526 12.9001L11.6503 15.5751C12.2169 16.1334 13.1836 15.7334 13.1836 14.9334L13.1836 9.74173L13.1836 5.06673C13.1836 4.26673 12.2169 3.86673 11.6503 4.43339L7.3336 8.75006C6.64193 9.43339 6.64193 10.5667 7.3336 11.2584Z"
                              fill="#142B7B"
                            />
                          </svg>
                          {newsView && (
                            <div className="Submenu">
                              <ul
                                className={
                                  urlPage === "en"
                                    ? "dropdown-menuSysEn dropdown-menu"
                                    : "dropdown-menuSysAr dropdown-menu"
                                }
                              >
                                {/* <ul className="dropdown-menu" style={{ marginLeft: urlPage === 'en' ? "165px" : '0px', marginTop: urlPage === 'en' ? "-48px" : '-48px', }}> */}
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "en/study-system-all/how-to-apply"
                                        : "/study-system-all/how-to-apply"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M5.58643 8.00005L7.19309 9.61339L10.4131 6.38672"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.16643 1.63289C7.62643 1.23956 8.37976 1.23956 8.84643 1.63289L9.89976 2.53956C10.0998 2.71289 10.4731 2.85289 10.7398 2.85289H11.8731C12.5798 2.85289 13.1598 3.43289 13.1598 4.13956V5.27289C13.1598 5.53289 13.2998 5.91289 13.4731 6.11289L14.3798 7.16622C14.7731 7.62622 14.7731 8.37956 14.3798 8.84622L13.4731 9.89956C13.2998 10.0996 13.1598 10.4729 13.1598 10.7396V11.8729C13.1598 12.5796 12.5798 13.1596 11.8731 13.1596H10.7398C10.4798 13.1596 10.0998 13.2996 9.89976 13.4729L8.84643 14.3796C8.38643 14.7729 7.63309 14.7729 7.16643 14.3796L6.11309 13.4729C5.91309 13.2996 5.53976 13.1596 5.27309 13.1596H4.11976C3.41309 13.1596 2.83309 12.5796 2.83309 11.8729V10.7329C2.83309 10.4729 2.69309 10.0996 2.52643 9.89956L1.62643 8.83956C1.23976 8.37956 1.23976 7.63289 1.62643 7.17289L2.52643 6.11289C2.69309 5.91289 2.83309 5.53956 2.83309 5.27956V4.13289C2.83309 3.42622 3.41309 2.84622 4.11976 2.84622H5.27309C5.53309 2.84622 5.91309 2.70622 6.11309 2.53289L7.16643 1.63289Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Admission Policy")}{" "}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "2px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "en/study-system-all/study-system"
                                        : "/study-system-all/study-system"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M14.6668 6.66634V9.99967C14.6668 13.333 13.3335 14.6663 10.0002 14.6663H6.00016C2.66683 14.6663 1.3335 13.333 1.3335 9.99967V5.99967C1.3335 2.66634 2.66683 1.33301 6.00016 1.33301H9.3335"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M14.6668 6.66634H12.0002C10.0002 6.66634 9.3335 5.99967 9.3335 3.99967V1.33301L14.6668 6.66634Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4.6665 8.66699H8.6665"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4.6665 11.333H7.33317"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Study System")}{" "}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "2px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "en/study-system-all/transfer"
                                        : "/study-system-all/transfer"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M14.6668 7.99967C14.6668 11.6797 11.6802 14.6663 8.00016 14.6663C4.32016 14.6663 2.0735 10.9597 2.0735 10.9597M2.0735 10.9597H5.08683M2.0735 10.9597V14.293M1.3335 7.99967C1.3335 4.31967 4.2935 1.33301 8.00016 1.33301C12.4468 1.33301 14.6668 5.03967 14.6668 5.03967M14.6668 5.03967V1.70634M14.6668 5.03967H11.7068"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Transfer Policy")}{" "}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "2px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "en/study-system-all/assessment-examination-policy"
                                        : "/study-system-all/assessment-examination-policy"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M7.3335 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V8.66634"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M10.6933 2.0135L5.43992 7.26684C5.23992 7.46684 5.03992 7.86017 4.99992 8.14684L4.71325 10.1535C4.60659 10.8802 5.11992 11.3868 5.84659 11.2868L7.85325 11.0002C8.13325 10.9602 8.52659 10.7602 8.73325 10.5602L13.9866 5.30684C14.8933 4.40017 15.3199 3.34684 13.9866 2.0135C12.6533 0.680168 11.5999 1.10684 10.6933 2.0135Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Assessments and examination Policy")}
                                  </Link>
                                </li>
                                <hr style={{ marginTop: "2px" }}></hr>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "en/study-system-all/scholarships"
                                        : "/study-system-all/scholarships"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M6.70001 1.6862L2.68668 4.3062C1.40001 5.1462 1.40001 7.0262 2.68668 7.8662L6.70001 10.4862C7.42001 10.9595 8.60668 10.9595 9.32668 10.4862L13.32 7.8662C14.6 7.0262 14.6 5.15287 13.32 4.31287L9.32668 1.69287C8.60668 1.21287 7.42001 1.21287 6.70001 1.6862Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M3.75325 8.71973L3.74658 11.8464C3.74658 12.6931 4.39992 13.5997 5.19992 13.8664L7.32658 14.5731C7.69325 14.6931 8.29992 14.6931 8.67325 14.5731L10.7999 13.8664C11.5999 13.5997 12.2532 12.6931 12.2532 11.8464V8.75306"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M14.2666 10V6"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Scholarship")}{" "}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/faculty-staff"
                              : "/faculty-staff"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          role="button"
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M7.99984 1.33268C4.33317 1.33268 1.33317 4.33268 1.33317 7.99935C1.33317 11.666 4.33317 14.666 7.99984 14.666C11.6665 14.666 14.6665 11.666 14.6665 7.99935C14.6665 4.33268 11.6665 1.33268 7.99984 1.33268Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 10.666L8 7.33268"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00342 5.33398L7.99743 5.33398"
                              stroke="#09154E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Faculty Staff")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "en/eelu-learning-model"
                              : "/eelu-learning-model"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14.6668 6.66634V9.99967C14.6668 13.333 13.3335 14.6663 10.0002 14.6663H6.00016C2.66683 14.6663 1.3335 13.333 1.3335 9.99967V5.99967C1.3335 2.66634 2.66683 1.33301 6.00016 1.33301H9.3335"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.6668 6.66634H12.0002C10.0002 6.66634 9.3335 5.99967 9.3335 3.99967V1.33301L14.6668 6.66634Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.6665 8.66699H8.6665"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.6665 11.333H7.33317"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("EELU Learning Model")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>

                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "en/international-relations"
                              : "/international-relations"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("International Relations")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={url}
                    role="button"
                    onClick={() => setUniAbout(!aboutUni)}
                  >
                    {t("Menu.Media")}
                  </Link>
                  {aboutUni && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/media" : "/media"}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onMouseEnter={() => setEventsView(!eventsView)}
                          onMouseLeave={() => setEventsView(!eventsView)}
                          onClick={handleEventsView}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M4.83999 1.33301H11.1533C11.5867 1.33301 11.9733 1.34636 12.32 1.39303C14.1667 1.59969 14.6667 2.46635 14.6667 4.83968V9.05302C14.6667 11.4264 14.1667 12.293 12.32 12.4997C11.9733 12.5463 11.5933 12.5597 11.1533 12.5597H4.83999C4.40665 12.5597 4.01999 12.5463 3.67332 12.4997C1.82665 12.293 1.32666 11.4264 1.32666 9.05302V4.83968C1.32666 2.46635 1.82665 1.59969 3.67332 1.39303C4.01999 1.34636 4.40665 1.33301 4.83999 1.33301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.05322 5.5459H11.5066"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.49316 9.40625H4.50649H11.5132"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.6665 14.667H11.3332"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.79647 5.53353H4.80246"
                              stroke="#09154E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.99618 5.53353H7.00216"
                              stroke="#09154E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("Media Coverage")}
                          <svg
                            style={{
                              marginRight: "6%",
                              transform:
                                urlPage === "en" ? "scaleX(-1)" : "none",
                              marginLeft: urlPage === "en" ? "5%" : "",
                            }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.3336 11.2584L8.97526 12.9001L11.6503 15.5751C12.2169 16.1334 13.1836 15.7334 13.1836 14.9334L13.1836 9.74173L13.1836 5.06673C13.1836 4.26673 12.2169 3.86673 11.6503 4.43339L7.3336 8.75006C6.64193 9.43339 6.64193 10.5667 7.3336 11.2584Z"
                              fill="#142B7B"
                            />
                          </svg>
                          {eventsView && (
                            <div className="Submenu">
                              <ul
                                className={
                                  urlPage === "en"
                                    ? "dropdown-menuEveEn dropdown-menu"
                                    : "dropdown-menuEveAr dropdown-menu"
                                }
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={
                                      urlPage === "en"
                                        ? "/en/media/sub-gallery"
                                        : "/media/sub-gallery"
                                    }
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M5.99984 6.66667C6.73622 6.66667 7.33317 6.06971 7.33317 5.33333C7.33317 4.59695 6.73622 4 5.99984 4C5.26346 4 4.6665 4.59695 4.6665 5.33333C4.6665 6.06971 5.26346 6.66667 5.99984 6.66667Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M1.77979 12.633L5.06645 10.4264C5.59312 10.073 6.35312 10.113 6.82645 10.5197L7.04645 10.713C7.56645 11.1597 8.40645 11.1597 8.92645 10.713L11.6998 8.33305C12.2198 7.88638 13.0598 7.88638 13.5798 8.33305L14.6665 9.26638"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Photo Gallery")}{" "}
                                  </Link>
                                </li>
                                {/* <hr style={{ marginTop: '2px' }}></hr> */}
                                {/* <li><Link className="dropdown-item" to="" style={{ textAlign: urlPage === 'en' ? "left" : 'right' }}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                                      <path d="M7.3335 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V8.66634" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M10.6933 2.0135L5.43992 7.26684C5.23992 7.46684 5.03992 7.86017 4.99992 8.14684L4.71325 10.1535C4.60659 10.8802 5.11992 11.3868 5.84659 11.2868L7.85325 11.0002C8.13325 10.9602 8.52659 10.7602 8.73325 10.5602L13.9866 5.30684C14.8933 4.40017 15.3199 3.34684 13.9866 2.0135C12.6533 0.680168 11.5999 1.10684 10.6933 2.0135Z" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    {t('Master These Discussion')}</Link></li> */}
                              </ul>
                            </div>
                          )}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/News" : "/News"}
                          onMouseEnter={() => setNewsView(!newsView)}
                          onMouseLeave={() => setNewsView(!newsView)}
                          onClick={handleNewsView}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14.6668 11.1605V3.11378C14.6668 2.31378 14.0135 1.72045 13.2202 1.78712H13.1802C11.7802 1.90712 9.6535 2.62045 8.46683 3.36712L8.3535 3.44045C8.16016 3.56045 7.84016 3.56045 7.64683 3.44045L7.48016 3.34045C6.2935 2.60045 4.1735 1.89378 2.7735 1.78045C1.98016 1.71378 1.3335 2.31378 1.3335 3.10712V11.1605C1.3335 11.8005 1.8535 12.4005 2.4935 12.4805L2.68683 12.5071C4.1335 12.7005 6.36683 13.4338 7.64683 14.1338L7.6735 14.1471C7.8535 14.2471 8.14016 14.2471 8.3135 14.1471C9.5935 13.4405 11.8335 12.7005 13.2868 12.5071L13.5068 12.4805C14.1468 12.4005 14.6668 11.8005 14.6668 11.1605Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 3.66016V13.6602"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.1665 5.66016H3.6665"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.6665 7.66016H3.6665"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("University News")}
                          <svg
                            style={{
                              marginRight: "25%",
                              transform:
                                urlPage === "en" ? "scaleX(-1)" : "none",
                              marginLeft: urlPage === "en" ? "5.5%" : "",
                            }}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.3336 11.2584L8.97526 12.9001L11.6503 15.5751C12.2169 16.1334 13.1836 15.7334 13.1836 14.9334L13.1836 9.74173L13.1836 5.06673C13.1836 4.26673 12.2169 3.86673 11.6503 4.43339L7.3336 8.75006C6.64193 9.43339 6.64193 10.5667 7.3336 11.2584Z"
                              fill="#142B7B"
                            />
                          </svg>
                          {newsView && (
                            <div className="Submenu">
                              <ul
                                className={
                                  urlPage === "en"
                                    ? "dropdown-menuNewsEn dropdown-menu"
                                    : "dropdown-menuNewsAr dropdown-menu"
                                }
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={urlPage === "en" ? "/en/News" : `/News`}
                                    role="button"
                                    style={{
                                      textAlign:
                                        urlPage === "en" ? "left" : "right",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        marginRight:
                                          urlPage === "en" ? "6px" : " 0px",
                                        marginLeft:
                                          urlPage === "en" ? "0px" : "5px",
                                      }}
                                    >
                                      <path
                                        d="M14.6668 11.1605V3.11378C14.6668 2.31378 14.0135 1.72045 13.2202 1.78712H13.1802C11.7802 1.90712 9.6535 2.62045 8.46683 3.36712L8.3535 3.44045C8.16016 3.56045 7.84016 3.56045 7.64683 3.44045L7.48016 3.34045C6.2935 2.60045 4.1735 1.89378 2.7735 1.78045C1.98016 1.71378 1.3335 2.31378 1.3335 3.10712V11.1605C1.3335 11.8005 1.8535 12.4005 2.4935 12.4805L2.68683 12.5071C4.1335 12.7005 6.36683 13.4338 7.64683 14.1338L7.6735 14.1471C7.8535 14.2471 8.14016 14.2471 8.3135 14.1471C9.5935 13.4405 11.8335 12.7005 13.2868 12.5071L13.5068 12.4805C14.1468 12.4005 14.6668 11.8005 14.6668 11.1605Z"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M8 3.66016V13.6602"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M5.1665 5.66016H3.6665"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M5.6665 7.66016H3.6665"
                                        stroke="#09154E"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    {t("Current News")}{" "}
                                  </Link>
                                </li>
                                {/* <li><a className="dropdown-item" href="#" style={{ marginTop: '-8%', textAlign: urlPage === 'en' ? "left" : 'right' }}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                                      <path d="M13 6.81348V12.6668C13 14.0001 12.6667 14.6668 11 14.6668H5C3.33333 14.6668 3 14.0001 3 12.6668V6.81348" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M3.3335 1.33301H12.6668C14.0002 1.33301 14.6668 1.99967 14.6668 3.33301V4.66634C14.6668 5.99967 14.0002 6.66634 12.6668 6.66634H3.3335C2.00016 6.66634 1.3335 5.99967 1.3335 4.66634V3.33301C1.3335 1.99967 2.00016 1.33301 3.3335 1.33301Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M6.78662 9.33301H9.21329" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    {t('News Archive')} </a></li> */}
                              </ul>
                            </div>
                          )}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      {/* <li><Link className="dropdown-item" to='' style={{ textAlign: urlPage === 'en' ? "left" : 'right' }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                            <path d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.3332 1.33301V6.573C10.3332 6.86633 9.98649 7.01299 9.77315 6.81966L8.22652 5.39303C8.09985 5.27303 7.89982 5.27303 7.77315 5.39303L6.22652 6.81966C6.01319 7.01299 5.6665 6.86633 5.6665 6.573V1.33301H10.3332Z" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.8335 9.33301H11.6668" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6 12H11.6667" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          {t('Documents and Brochures')}</Link></li><hr style={{ marginTop: '2px' }}></hr> */}
                      <li>
                        <Link
                          className="dropdown-item"
                          to="https://www.youtube.com/@egyptelu/featured"
                          target="_blank"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.06689 7.99999V7.01333C6.06689 5.73999 6.96689 5.22666 8.06689 5.85999L8.92023 6.35333L9.77356 6.84666C10.8736 7.47999 10.8736 8.51999 9.77356 9.15333L8.92023 9.64666L8.06689 10.14C6.96689 10.7733 6.06689 10.2533 6.06689 8.98666V7.99999Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Video")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <Link
                  className="navbar-brand"
                  to={urlPage === "en" ? "/en" : ""}
                >
                  <div
                    className="HeaderLogo"
                    aria-label="eelu logo white"
                  ></div>
                </Link>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={url}
                    role="button"
                    onClick={() => setUniAbout(!aboutUni)}
                  >
                    {t("Menu.University life")}
                  </Link>
                  {aboutUni && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                      style={{ marginRight: urlPage === "en" ? "-32%" : "" }}
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/Learning-Center"
                              : "/Learning-Center"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M7.99992 8.95297C9.14867 8.95297 10.0799 8.02172 10.0799 6.87297C10.0799 5.72422 9.14867 4.79297 7.99992 4.79297C6.85117 4.79297 5.91992 5.72422 5.91992 6.87297C5.91992 8.02172 6.85117 8.95297 7.99992 8.95297Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                            />
                            <path
                              d="M2.4133 5.65968C3.72664 -0.113657 12.28 -0.106991 13.5866 5.66634C14.3533 9.05301 12.2466 11.9197 10.4 13.693C9.05997 14.9863 6.93997 14.9863 5.5933 13.693C3.7533 11.9197 1.64664 9.04634 2.4133 5.65968Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                            />
                          </svg>

                          {t("Learning Centers")}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/media/student-activities"
                              : "/media/student-activities"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.88672 9.65984L6.47339 7.59984C6.70005 7.30651 7.12005 7.25318 7.41339 7.47984L8.63339 8.43984C8.92672 8.66651 9.34672 8.61318 9.57339 8.32651L11.1134 6.33984"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Student Activities")}{" "}
                        </Link>
                      </li>
                      {/* <hr style={{ marginTop: '2px' }}></hr> */}
                      {/* <li><Link className="dropdown-item" to='/e-club' style={{ textAlign: urlPage === 'en' ? "left" : 'right' }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                            <path d="M11 6.33301L8.2 9.13301L7.13333 7.53301L5 9.66634" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.6665 6.33301H10.9998V7.66634" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          {t('E-Club')}   </Link></li><hr style={{ marginTop: '2px' }}></hr>
                        <li><Link className="dropdown-item" to='/tico' style={{ textAlign: urlPage === 'en' ? "left" : 'right' }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                            <path d="M6.0135 1.89312L2.42016 4.69312C1.82016 5.15978 1.3335 6.15312 1.3335 6.90645V11.8464C1.3335 13.3931 2.5935 14.6598 4.14016 14.6598H11.8602C13.4068 14.6598 14.6668 13.3931 14.6668 11.8531V6.99978C14.6668 6.19312 14.1268 5.15978 13.4668 4.69978L9.34683 1.81312C8.4135 1.15978 6.9135 1.19312 6.0135 1.89312Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11 7.66699L8.2 10.467L7.13333 8.86699L5 11.0003" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.6665 7.66699H10.9998V9.00033" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          {t('TICO')}</Link></li> */}
                    </ul>
                  )}
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={url}
                    role="button"
                    onClick={() => setUniAbout(!aboutUni)}
                  >
                    {t("Menu.Research")}
                  </Link>
                  {aboutUni && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                      style={{ marginRight: urlPage === "en" ? "" : "" }}
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/e-library" : "/e-library"}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M9.42015 7.56038C9.68015 6.46705 8.72681 5.51368 7.63348 5.77368C7.12015 5.90034 6.70015 6.32036 6.57348 6.83369C6.31348 7.92703 7.26681 8.88036 8.36015 8.62036C8.88015 8.49369 9.30015 8.07371 9.42015 7.56038Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.0068 11.4597C13.1468 10.393 13.8601 8.87966 13.8601 7.19299C13.8601 3.95299 11.2335 1.33301 8.00013 1.33301C4.7668 1.33301 2.14014 3.95966 2.14014 7.19299C2.14014 8.88633 2.86014 10.413 4.01347 11.4797"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.33318 9.69987C4.71985 9.04654 4.33984 8.16653 4.33984 7.1932C4.33984 5.1732 5.97985 3.5332 7.99985 3.5332C10.0198 3.5332 11.6599 5.1732 11.6599 7.1932C11.6599 8.16653 11.2798 9.03987 10.6665 9.69987"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.86665 11.1065L5.90665 12.2999C5.14665 13.2532 5.81998 14.6599 7.03998 14.6599H8.95331C10.1733 14.6599 10.8533 13.2465 10.0867 12.2999L9.12665 11.1065C8.55332 10.3799 7.44665 10.3799 6.86665 11.1065Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("The Virtual Library")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/research-projects"
                              : "/research-projects"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M12.2133 8.00045C13.9467 8.00045 14.6667 7.33378 14.0267 5.14711C13.5933 3.67378 12.3267 2.40711 10.8533 1.97378C8.66667 1.33378 8 2.05378 8 3.78711V5.70711C8 7.33378 8.66667 8.00045 10 8.00045H12.2133Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M13.3334 9.79952C12.7134 12.8862 9.75345 15.1262 6.38678 14.5795C3.86012 14.1729 1.82678 12.1395 1.41345 9.61285C0.87345 6.25952 3.10012 3.29952 6.17345 2.67285"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Research Projects")}{" "}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={
                      urlPage === "en"
                        ? "/en/specialized-centers"
                        : "/specialized-centers"
                    }
                    role="button"
                    onClick={() => setUniAbout(!aboutUni)}
                  >
                    {t("EELU Data.CategoryPath.Specialized Centers")}
                  </Link>
                  {aboutUni && (
                    <ul
                      className={
                        urlPage === "en"
                          ? "dropdown-menu centerUlEn"
                          : "dropdown-menu"
                      }
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <Link
                          className="dropdown-item centerEn"
                          to="/clc"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => setUniAbout(!aboutUni)}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M6.66683 11.2999H4.14016C1.8935 11.2999 1.3335 10.7399 1.3335 8.49319V4.49319C1.3335 2.24652 1.8935 1.68652 4.14016 1.68652H11.1602C13.4068 1.68652 13.9668 2.24652 13.9668 4.49319"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 14.3131V11.2998"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.3335 8.63379H6.66683"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.49316 14.3135H6.6665"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.6668 8.5329V12.3396C14.6668 13.9196 14.2735 14.3129 12.6935 14.3129H10.3268C8.74685 14.3129 8.35352 13.9196 8.35352 12.3396V8.5329C8.35352 6.9529 8.74685 6.55957 10.3268 6.55957H12.6935C14.2735 6.55957 14.6668 6.9529 14.6668 8.5329Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.4962 12.1663H11.5022"
                              stroke="#09154E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Continuing Learning Center")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>

                      {Centers.map((centerrr) => {
                        return (
                          <>
                            <li>
                              <Link
                                onClick={() => toggleNav()}
                                className={
                                  urlPage === "en"
                                    ? "dropdown-item centerEn"
                                    : "dropdown-item centerAr"
                                }
                                to={`/specialized-centers/${centerrr.titleEn.replace(
                                  / /g,
                                  "-"
                                )}`}
                                state={centerrr}
                                style={{
                                  // width: 58%;

                                  textAlign:
                                    urlPage === "en" ? "left" : "right",
                                  // display: 'inline-block',
                                  // width: '95%', // Adjust width to control where the line breaks
                                  whiteSpace: "normal",
                                  backgroundColor: "white",
                                  // wordWrap: 'break-word',
                                  // overflowWrap: 'break-word'
                                }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    marginRight:
                                      urlPage === "en" ? "6px" : "0px",
                                    marginLeft:
                                      urlPage === "en" ? "0px" : "5px",
                                  }}
                                >
                                  <path
                                    d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
                                    stroke="#09154E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.9999 13.5138C10.3532 13.5138 12.5466 12.1271 14.0732 9.72714C14.6732 8.78714 14.6732 7.20714 14.0732 6.26714C12.5466 3.86714 10.3532 2.48047 7.9999 2.48047C5.64656 2.48047 3.45323 3.86714 1.92656 6.26714C1.32656 7.20714 1.32656 8.78714 1.92656 9.72714C3.45323 12.1271 5.64656 13.5138 7.9999 13.5138Z"
                                    stroke="#09154E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                {urlPage === "en"
                                  ? centerrr.titleEn
                                  : centerrr.title}
                              </Link>
                            </li>

                            <hr style={{ marginTop: "2px" }}></hr>
                          </>
                        );
                      })}

                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/quality" : "/quality"}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.3335 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 8.66699V11.3337"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Qality Center")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item centerEn"
                          to={urlPage === "en" ? "/en/iso" : "/iso"}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.3335 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 8.66699V11.3337"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("ISO")}{" "}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={url}
                    role="button"
                    onClick={() => setUniAbout(!aboutUni)}
                  >
                    {t("Menu.Services")}
                  </Link>
                  {aboutUni && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                      style={{ marginRight: urlPage === "en" ? "" : "" }}
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/ssu-form" : "/ssu-form"}
                          role="button"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("SSU")}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/graduation-party"
                              : "/graduation-party"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 8.66699H8.00016"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 11.333H10.6668"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("Graduation Party")}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr>
                      {/* <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/ssu-form"
                              : "/Scholarship-form"
                          }
                          role="button"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Scholarship Form")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr> */}
                      {/* <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/ssu-form"
                              : "/Transfer-between-centres-form"
                          }
                          role="button"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Transfer between Centres")}{" "}
                        </Link>
                      </li>
                      <hr style={{ marginTop: "2px" }}></hr> */}
                      {/* <li><Link className="dropdown-item" to={urlPage === 'en' ? '/en/ssu-major' : '/ssu-major'} role="button" style={{ textAlign: urlPage === 'en' ? "left" : 'right', backgroundColor: 'white' }} onClick={() => { setUniAbout(!aboutUni); toggleNav(); }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                            <path d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          {t('Change Specialty')}  </Link></li><hr style={{ marginTop: '2px' }}></hr> */}
                      {/* <li><Link className="dropdown-item" to={urlPage === 'en' ? '/en/it-master-appel' : '/it-master-appel'} role="button" style={{ textAlign: urlPage === 'en' ? "left" : 'right', backgroundColor: 'white' }} onClick={() => { setUniAbout(!aboutUni); toggleNav(); }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                            <path d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z" stroke="#09154E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          {t("Master's Petitions")}  </Link></li><hr style={{ marginTop: '2px' }}></hr> */}
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/recruitment"
                              : "/recruitment"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 8.66699H8.00016"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 11.333H10.6668"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("Recruitment")}
                        </Link>
                      </li>
                      {/* <hr style={{ marginTop: "2px" }}></hr>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/employee-complaints-and-suggestions"
                              : "/employee-complaints-and-suggestions"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => setUniAbout(!aboutUni)}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 8.66699H8.00016"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.3335 11.333H10.6668"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("President Complaints")}
                        </Link>
                      </li>
                       */}

                      <hr style={{ marginTop: "2px" }}></hr>
                      {/* <li><Link className="dropdown-item" to={urlPage === 'en' ? '/en/technical-tiket-form' : '/technical-tiket-form'} style={{ textAlign: urlPage === 'en' ? "left" : 'right', backgroundColor: 'white' }} onClick={() => setUniAbout(!aboutUni)}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                            <path d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.3335 8.66699H8.00016" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.3335 11.333H10.6668" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          {t('Technical Support For Employee')}</Link></li>
                        <hr style={{ marginTop: '2px' }}></hr> */}

                      {/* <li><Link className="dropdown-item" to='' style={{ textAlign: urlPage === 'en' ? "left" : 'right' }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: urlPage === 'en' ? "6px" : " 0px", marginLeft: urlPage === 'en' ? "0px" : "5px" }}>
                            <path d="M1.3335 1.33301H2.4935C3.2135 1.33301 3.78016 1.95301 3.72016 2.66634L3.16683 9.30634C3.0735 10.393 3.93349 11.3263 5.02683 11.3263H12.1268C13.0868 11.3263 13.9268 10.5397 14.0002 9.58634L14.3602 4.58634C14.4402 3.47968 13.6002 2.57967 12.4868 2.57967H3.88017" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.8333 14.6667C11.2936 14.6667 11.6667 14.2936 11.6667 13.8333C11.6667 13.3731 11.2936 13 10.8333 13C10.3731 13 10 13.3731 10 13.8333C10 14.2936 10.3731 14.6667 10.8333 14.6667Z" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.49984 14.6667C5.96007 14.6667 6.33317 14.2936 6.33317 13.8333C6.33317 13.3731 5.96007 13 5.49984 13C5.0396 13 4.6665 13.3731 4.6665 13.8333C4.6665 14.2936 5.0396 14.6667 5.49984 14.6667Z" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6 5.33301H14" stroke="#09154E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                          المشتريات</Link></li>
                          <hr style={{ marginTop: '2px' }}></hr> */}
                      <li>
                        <Link
                          className="dropdown-item"
                          to={urlPage === "en" ? "/en/e-Service" : "/e-Service"}
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            setUniAbout(!aboutUni);
                            toggleNav();
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M6.66683 11.2999H4.14016C1.8935 11.2999 1.3335 10.7399 1.3335 8.49319V4.49319C1.3335 2.24652 1.8935 1.68652 4.14016 1.68652H11.1602C13.4068 1.68652 13.9668 2.24652 13.9668 4.49319"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 14.3131V11.2998"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.3335 8.63379H6.66683"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.49316 14.3135H6.6665"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.6668 8.5329V12.3396C14.6668 13.9196 14.2735 14.3129 12.6935 14.3129H10.3268C8.74685 14.3129 8.35352 13.9196 8.35352 12.3396V8.5329C8.35352 6.9529 8.74685 6.55957 10.3268 6.55957H12.6935C14.2735 6.55957 14.6668 6.9529 14.6668 8.5329Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.4962 12.1663H11.5022"
                              stroke="#09154E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("E-Service")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <Search toggleNav={toggleNav} />
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbarr;
