import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import DefaultHeader from "../components/DefaultHeader";
import { useTranslation } from "react-i18next";
import HeaderImg from "../pages/Imgs/J4o.gif";
import { Col, Row, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";

const Leads = () => {
  const [t, i18n] = useTranslation();
  var url = document.referrer;
  const myURL = url.split("/");
  // document.getElementById("00N8e000002MITr").value=myURL[2];
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    state: "",
    type_of_certificate: "",
    year_of_certificate: "",
    college: "",
    location_first_Preference: "",
    city: "",
    student_grade: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.createElement("form");
    form.action =
      "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
    form.method = "POST";

    const fields = {
      oid: "00D4L000000i6vU",
      retURL: "https://www.eelu.edu.eg/thank-you",
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mobile: formData.mobile,
      state: formData.state,
      "00N4L000009hYrM": formData.type_of_certificate,
      "00N4L000009hYrH": formData.year_of_certificate,
      "00NR5000001TQHt": formData.college,
      "00N4L000009hYrL": formData.location_first_Preference,
      // city: formData.city,
      "00N4L000009hYrK": formData.student_grade,
      "00N8e000002MITr": myURL[2],
    };

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };
  // console.log("0000000000000000000000000000000000000000");
  // console.log(myURL[2]);
  return (
    <Fragment>
      <DefaultHeader
        title={"سجل الآن"}
        backgroundImage={HeaderImg}
        style={"LeadStyle"}
      />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={12} md={12}>
                <br />
                <div style={{ color: "rgb(17, 38, 113)", marginRight: "-3%" }}>
                  <svg
                    width="50"
                    height="35"
                    viewBox="0 0 215 259"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* SVG Path data */}
                  </svg>
                  سجل الآن
                </div>
              </Col>
            </Row>
          </div>
          <h3 style={{ marginTop: "2%", color: "rgb(17, 38, 113)" }}>
            مرحباً بكم بالموقع الإلكتروني الخاص بالجامعة المصرية للتعلم
            الإلكتروني الأهلية. تأسست الجامعة منذ عام 2008 كأول جامعة إلكترونية
            في مصر، حيث تمنح شهادة معتمدة من وزارة التعليم العالي ومعادلة من
            المجلس الأعلى للجامعات. الجامعة لديها ثلاثة عشر فرعاً دراسياً داخل
            الجامعات الحكومية في مختلف المحافظات. سجل بياناتك الآن!
          </h3>
          <Row>
            <Col
              className="BodyCol"
              xs={{ span: 11, order: 2 }}
              md={{ span: 5, order: 1 }}
              lg={{ span: 5, order: 1 }}
            >
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="retURL"
                  value="http://www.eelu.edu.eg/thank-you"
                />
                <label htmlFor="first_name">الأسم الأول</label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  maxLength="80"
                  size="20"
                  className="form-control"
                  required
                  value={formData.first_name}
                  onChange={handleChange}
                />
                <label htmlFor="last_name">إسم الأب أو العائلة </label>
                <input
                  id="last_name"
                  name="last_name"
                  maxLength="80"
                  size="20"
                  type="text"
                  className="form-control"
                  required
                  value={formData.last_name}
                  onChange={handleChange}
                />
                <label htmlFor="email">البريد الإلكتروني </label>
                <input
                  id="email"
                  maxLength="80"
                  name="email"
                  size="20"
                  type="text"
                  className="form-control"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
                <label htmlFor="phone">الموبايل</label>
                <input
                  id="mobile"
                  maxlength="40"
                  name="mobile"
                  size="20"
                  type="text"
                  className="form-control"
                  pattern="^[\u0660-\u0669\u06F0-\u06F90-9]{11}"
                  value={formData.mobile}
                  onChange={handleChange}
                />
                <label htmlFor="state">المحافظة</label>
                <select
                  id="state"
                  name="state"
                  title="state"
                  className="form-control"
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value="">--None--</option>
                  <option value="الإسكندرية">الإسكندرية</option>
                  <option value="الإسماعيلية">الإسماعيلية</option>
                  <option value="الأقصر">الأقصر</option>
                  <option value="البحر الأحمر">البحر الأحمر</option>
                  <option value="البحيرة">البحيرة</option>
                  <option value="الجيزة">الجيزة</option>
                  <option value="الدقهلية">الدقهلية</option>
                  <option value="السويس">السويس</option>
                  <option value="الشرقية">الشرقية</option>
                  <option value="الغربية">الغربية</option>
                  <option value="الفيوم">الفيوم</option>
                  <option value="القاهرة">القاهرة</option>
                  <option value="القليوبية">القليوبية</option>
                  <option value="المنوفية">المنوفية</option>
                  <option value="المنيا">المنيا</option>
                  <option value="الوادي الجديد">الوادي الجديد</option>
                  <option value="أسوان">أسوان</option>
                  <option value="أسيوط">أسيوط</option>
                  <option value="بني سويف">بني سويف</option>
                  <option value="بور سعيد">بور سعيد</option>
                  <option value="جنوب سيناء">جنوب سيناء</option>
                  <option value="دمياط">دمياط</option>
                  <option value="سوهاج">سوهاج</option>
                  <option value="شمال سيناء">شمال سيناء</option>
                  <option value="قنا">قنا</option>
                  <option value="كفر الشيخ">كفر الشيخ</option>
                  <option value="مطروح">مطروح</option>
                </select>
                <label htmlFor="type_of_certificate">نوع الشهادة</label>
                <select
                  id="00N4L000009hYrM"
                  name="type_of_certificate"
                  title="Type of Certificate"
                  className="form-control"
                  value={formData.type_of_certificate}
                  onChange={handleChange}
                >
                  <option value="">--None--</option>
                  <option value="ثانوية(علمي)">ثانوية(علمي)</option>
                  <option value="ثانوية(أدبي)">ثانوية(أدبي)</option>
                  <option value="ثانوية عربية">ثانوية عربية</option>
                  <option value="ثانوية أمريكية">ثانوية أمريكية</option>
                  <option value="ثانوية بريطانية">ثانوية بريطانية</option>
                  <option value="ثانوية ازهرية">ثانوية ازهرية</option>
                  <option value="بكالوريوس/ليسانس">بكالوريوس/ليسانس</option>
                  <option value="دبلوم تجاري">دبلوم تجاري</option>
                  <option value="أخرى">أخرى</option>
                </select>
                <label htmlFor="year_of_certificate">
                  {" "}
                  سنة الحصول علي الشهادة{" "}
                </label>
                <select
                  id="00N4L000009hYrH"
                  name="year_of_certificate"
                  className="form-control"
                  value={formData.year_of_certificate}
                  onChange={handleChange}
                >
                  <option value="">--None--</option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                </select>
                <label htmlFor="web-college">البرامج</label>
                <select
                  id="00NR5000001TQHt"
                  name="college"
                  title="College"
                  className="form-control"
                  value={formData.college}
                  onChange={handleChange}
                >
                  <option value="">--None--</option>

                  <option value="كلية ادارة أعمال">كلية ادارة أعمال</option>
                  <option value="كلية حاسبات وتكنولوجيا المعلومات">
                    كلية حاسبات وتكنولوجيا المعلومات
                  </option>
                  <option value="دبلوم تربوي">دبلوم تربوي</option>
                  <option value="ماجستير في هندسة البرمجيات">
                    ماجستير في هندسة البرمجيات
                  </option>
                  <option value="ماجستير في التربية - كلية الدراسات التربوية">
                    ماجستير في التربية - كلية الدراسات التربوية
                  </option>
                  <option value="دبلوم تربوي خاص - كلية الدراسات التربوية">
                    دبلوم تربوي خاص - كلية الدراسات التربوية
                  </option>
                  <option value="دبلوم تربوي مهني- كلية الدراسات">
                    دبلوم تربوي مهني- كلية الدراسات التربوية
                  </option>
                  <option value="دبلوم تربوي عام - كلية الدراسات">
                    دبلوم تربوي عام - كلية الدراسات التربوية
                  </option>
                </select>
                <label htmlFor="location_first_Preference">الفرع الدراسي</label>
                <select
                  id="00N4L000009hYrL"
                  name="location_first_Preference"
                  title="Targeted Location"
                  className="form-control"
                  required
                  value={formData.location_first_Preference}
                  onChange={handleChange}
                >
                  <option value="">أختر الفرع</option>
                  <option value="عين شمس">عين شمس</option>
                  <option value="أسيوط">أسيوط</option>
                  <option value="الإسكندرية">الإسكندرية</option>
                  <option value="المنوفية">المنوفية</option>
                  <option value="بني سويف">بني سويف</option>
                  <option value="قنا">قنا</option>
                  <option value="الغردقة">الغردقة</option>
                  <option value="سوهاج">سوهاج</option>
                  <option value="أسوان">أسوان</option>
                  <option value="السادات">السادات</option>
                  <option value="الإسماعيلية">الإسماعيلية</option>
                  <option value="الفيوم">الفيوم</option>
                  <option value="طنطا">طنطا</option>
                </select>
                <label htmlFor="student_grade">
                  المجموع / درجة الطالب(يرجي ادخال الدرجات بالنسبة المئوية)
                </label>{" "}
                <input
                  type="number"
                  id="00N4L000009hYrK"
                  name="student_grade"
                  min="0"
                  max="100"
                  step="0.01"
                  className="form-control"
                  required
                  value={formData.student_grade}
                  onChange={handleChange}
                />
                <br></br>
                <button
                  type="submit"
                  className="btn btn-info"
                  style={{
                    backgroundColor: " rgb(17 35 97)",
                    justifyContent: "center",
                    padding: " 8px 24px",
                    borderRadius: "8px",
                    color: " #f4f5fc",
                    fontSize: "20px",
                    border: "0px",
                    marginTop: "2%",
                    marginBottom: "3%",
                  }}
                  // disabled={}
                >
                  {t("Submit")}
                </button>
              </form>
            </Col>

            <Col
              className="BodyCol"
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              lg={{ span: 6, order: 1 }}
            >
              <iframe
                className="learningCenterVideo1"
                tilte="EELU"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/ha-IqzvNbeQ?si=pVfvQYYAwBsZ-TR2"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Leads;
