import React, { Fragment, useEffect, useState } from "react";
import "../styles/Main.scss";
import { useTranslation } from "react-i18next";
import DefaultHeader from "../components/DefaultHeader";
import { Col, Row, Container } from "react-bootstrap";
import scentrs from "../pages/Imgs/Scenter.png";
import "../styles/componentsScss/SpecialCenters.scss";
import test from "../pages/Imgs/sCenterIconTest.png";
import { BASE_URL } from "../components/apiConfig";
import axios from "axios";
import { Link } from "react-router-dom";

function SpecialCenter() {
  const [t, i18n] = useTranslation();

  const url = window.location.href.split("/");

  const urlPage = url[3];
  const [Centers, setCenter] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/specializedCenters`).then((res) => {
      setCenter(res.data);
      // console.log(res.data)
    });
  }, []);
  return (
    <Fragment>
      <DefaultHeader
        title={t("EELU Data.CategoryPath.Specialized Centers")}
        backgroundImage={scentrs}
      />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <Row>
            {Centers.map((centrr) => {
              return (
                <Col xs={12} md={6} lg={6}>
                  <Link
                    to={`/specialized-centers/${centrr.titleEn.replace(
                      / /g,
                      "-"
                    )}`}
                    style={{ color: "inherit" }}
                    state={centrr}
                  >
                    <svg
                      width="70%" // Make the SVG width 100% of its container
                      height="auto" // Make the SVG height auto so it adjusts proportionally
                      viewBox="0 0 762 236" // Make sure the viewBox matches the SVG dimensions
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: "3%" }}
                    >
                      <g clipPath="url(#clip0_4076_4599)">
                        <rect width="762" height="236" rx="118" fill="white" />
                        <path
                          d="M236 0H118C52.8304 0 0 52.8304 0 118V118C0 183.17 52.8304 236 118 236H236"
                          stroke="#142B7B"
                          strokeWidth="16"
                        />
                        <g>
                          <rect
                            x="542"
                            y="16"
                            width="204"
                            height="204"
                            rx="102"
                            fill="url(#paint0_radial_4076_4599)"
                          ></rect>

                          <image
                            x="572"
                            y="60"
                            width="144"
                            height="120"
                            href={`${BASE_URL}/specializedCenters/${centrr.icon}`}
                          />
                        </g>

                        {urlPage === "en" ? (
                          <foreignObject
                            x="10%"
                            y="15%"
                            width="60%"
                            height="160"
                          >
                            <div
                              style={{
                                fontSize: "32.5px",
                                fontWeight: "700",
                                color: "rgb(9, 21, 78)",
                                whiteSpace: "wrap",
                              }}
                            >
                              {centrr.titleEn}
                            </div>
                          </foreignObject>
                        ) : (
                          <text
                            x="70%"
                            y="30%"
                            textAnchor=""
                            alignmentBaseline=""
                            fill="rgb(9, 21, 78)"
                            fontWeight="700"
                            fontSize="36"
                          >
                            {centrr.title}
                          </text>
                        )}
                        {urlPage === "en" ? (
                          <foreignObject
                            x="75"
                            y="135"
                            width="360"
                            height="160"
                          >
                            <div
                              style={{
                                fontSize: "24px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: "2",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {centrr.bodyEn}
                            </div>
                          </foreignObject>
                        ) : (
                          <foreignObject
                            x="165"
                            y="100"
                            width="360"
                            height="160"
                          >
                            <div
                              style={{
                                fontSize: "24px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: "2",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {centrr.body}
                            </div>
                          </foreignObject>
                        )}
                      </g>
                      <defs>
                        <radialGradient
                          id="paint0_radial_4076_4599"
                          cx="0"
                          cy="0"
                          r="1"
                          gradientUnits="userSpaceOnUse"
                          gradientTransform="translate(608.522 91.3913) rotate(50.3893) scale(166.939)"
                        >
                          <stop offset="0.09375" stopColor="#445BAE" />
                          <stop offset="1" stopColor="#082177" />
                        </radialGradient>
                        <clipPath id="clip0_4076_4599">
                          <rect
                            width="762"
                            height="236"
                            rx="118"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                </Col>
              );
            })}
          </Row>
          <br></br>
        </Container>
      </div>
    </Fragment>
  );
}
export default SpecialCenter;
