import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Main.scss";
import { useTranslation } from "react-i18next";
import siteMap from "../pages/Imgs/sitemapp.png";
import DefaultHeader from "../components/DefaultHeader";
import { Link } from "react-router-dom";
import { BASE_URL } from "../components/apiConfig";
import axios from "axios";

function Sitemap() {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  const [allnews, setAllnews] = useState([]);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/news/all`).then((res) => {
      setAllnews(res.data.data);
      // console.log(res.data)
    });
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/web/gallery/all`).then((res) => {
      setMedia(res.data.data);
      // console.log(res.data.data)
    });
  }, []);
  return (
    <Fragment>
      <DefaultHeader title={t("Site Map")} backgroundImage={siteMap} />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div style={{ color: "#09154E" }}>
                  <br />
                  <svg
                    width="50"
                    height="35"
                    viewBox="0 0 215 259"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3480_4927)">
                      <path
                        d="M124.566 163.976C124.158 162.903 122.956 162.362 121.882 162.77C120.809 163.177 120.268 164.38 120.675 165.453L154.662 254.839H62.3182L77.8801 213.907H121.716C122.864 213.907 123.796 212.975 123.796 211.827C123.796 210.679 122.864 209.747 121.716 209.747H79.4613L85.5363 193.766H110.718C111.867 193.766 112.799 192.834 112.799 191.686C112.799 190.537 111.867 189.605 110.718 189.605H87.1216L96.3048 165.453C96.7126 164.38 96.1716 163.177 95.0981 162.77C94.0246 162.362 92.8221 162.903 92.4143 163.976L57.3542 256.183C57.1129 256.824 57.1961 257.539 57.5872 258.105C57.9742 258.667 58.615 259.004 59.3015 259.004H157.679C158.361 259.004 159.002 258.667 159.393 258.105C159.78 257.543 159.867 256.824 159.626 256.183L124.566 163.976Z"
                        fill="#061041"
                      />
                      <path
                        d="M212.92 142.45H115.304C114.022 139.5 112.445 137.07 110.619 135.073C110.606 135.061 112.82 133.759 113.032 133.655C113.91 133.218 114.817 132.843 115.741 132.523C117.742 131.828 119.827 131.383 121.924 131.083C127.025 130.355 132.222 130.422 137.357 130.588C143.569 130.788 149.777 131.179 155.985 131.47C162.193 131.761 168.243 131.99 174.381 132.061C180.502 132.128 186.627 132.04 192.735 131.641C196.059 131.424 199.376 131.108 202.679 130.676C203.82 130.526 204.618 129.477 204.469 128.337C204.319 127.197 203.266 126.394 202.13 126.548C196.259 127.33 190.334 127.692 184.413 127.842C178.263 127.996 172.109 127.909 165.959 127.709C159.809 127.509 154.021 127.214 148.059 126.91C145.034 126.91 141.988 126.594 138.963 126.469C135.938 126.344 133.2 126.274 130.321 126.315C125.086 126.39 119.748 126.827 114.755 128.508C112.162 129.377 109.687 130.601 107.498 132.244C107.456 132.273 104.24 130.193 103.895 130.018C102.501 129.315 101.04 128.745 99.5505 128.283C96.4589 127.322 93.2384 126.823 90.0136 126.557C81.4879 125.858 72.9205 126.611 64.3989 127.039C55.8773 127.468 48.1296 127.826 39.9866 127.905C30.9366 127.988 21.8491 127.738 12.8698 126.548C11.7297 126.399 10.6853 127.197 10.5313 128.337C10.3774 129.477 11.1804 130.522 12.3205 130.676C28.4026 132.814 44.7676 132.165 60.9203 131.383C68.822 131 76.7486 130.393 84.6668 130.505C90.463 130.588 96.617 131.017 101.901 133.63C102.118 133.738 104.39 135.065 104.377 135.082C102.551 137.079 100.974 139.508 99.692 142.458H2.08047C0.932051 142.458 0 143.39 0 144.539C0 145.687 0.932051 146.619 2.08047 146.619H101.094C101.956 146.619 102.725 146.091 103.037 145.283C104.215 142.217 105.709 139.766 107.502 137.807C109.3 139.771 110.789 142.221 111.967 145.283C112.275 146.086 113.049 146.619 113.91 146.619H212.924C214.072 146.619 215.004 145.687 215.004 144.539C215.004 143.39 214.072 142.458 212.924 142.458L212.92 142.45Z"
                        fill="#061041"
                      />
                      <path
                        d="M84.24 114.387C84.9391 115.061 85.7796 115.535 86.6451 115.219C87.5646 114.878 87.9599 113.725 87.7685 112.76C87.5771 111.795 86.9571 110.988 86.358 110.21C84.2317 107.464 82.1554 104.676 80.129 101.855C74.5742 94.1165 69.2981 85.862 67.3175 76.5464C64.151 61.664 70.4923 45.2589 82.6797 36.1472C94.8671 27.0356 112.21 25.5336 125.908 32.1614C133.734 35.9475 140.367 42.2092 144.457 49.8855C148.543 57.5576 150.041 66.611 148.485 75.1651C146.754 84.6679 141.486 93.1222 135.902 101.006C133.734 104.069 131.504 107.089 129.211 110.06C128.379 111.142 127.489 112.394 127.718 113.738C127.888 114.741 129.033 115.406 129.998 115.227C131.101 115.019 131.7 113.754 132.332 112.927C134.321 110.33 136.273 107.709 138.182 105.055C144.291 96.563 150.099 87.5096 152.271 77.2787C155.584 61.7057 149.442 44.5849 137.196 34.4081C124.951 24.2313 107.196 21.294 92.2082 26.6611C81.8267 30.3807 72.8141 37.903 67.5047 47.568C62.1954 57.233 60.6933 68.97 63.6143 79.6003C65.7322 87.3098 70.0138 94.2289 74.4826 100.857C77.3578 105.117 80.3329 109.307 83.4078 113.426C83.6533 113.75 83.9321 114.091 84.2442 114.387H84.24Z"
                        fill="#061041"
                      />
                      <path
                        d="M108.489 51.2211C115.954 51.2211 122.025 57.2914 122.025 64.7555C122.025 65.9038 122.957 66.8358 124.105 66.8358C125.254 66.8358 126.186 65.9038 126.186 64.7555C126.186 54.9989 118.247 47.0605 108.489 47.0605C98.7321 47.0605 90.793 54.9989 90.793 64.7555C90.793 74.512 98.7321 82.4504 108.489 82.4504C109.638 82.4504 110.57 81.5184 110.57 80.3701C110.57 79.2218 109.638 78.2898 108.489 78.2898C101.025 78.2898 94.9539 72.2195 94.9539 64.7555C94.9539 57.2914 101.025 51.2211 108.489 51.2211Z"
                        fill="#061041"
                      />
                      <path
                        d="M139.834 18.5357C149.683 22.9126 158.625 33.78 160.631 43.8112C160.83 44.8014 161.696 45.4837 162.669 45.4837C162.807 45.4837 162.944 45.4713 163.081 45.4421C164.209 45.2175 164.937 44.1191 164.712 42.9957C162.428 31.5749 152.675 19.6882 141.528 14.7329C140.475 14.2669 139.247 14.7412 138.781 15.7897C138.315 16.8382 138.786 18.0697 139.838 18.5357H139.834Z"
                        fill="#061041"
                      />
                      <path
                        d="M145.824 4.07738C155.664 6.98978 176.248 29.8813 178.133 45.3544C178.262 46.4112 179.161 47.1851 180.197 47.1851C180.28 47.1851 180.368 47.1809 180.451 47.1685C181.591 47.0312 182.402 45.991 182.265 44.851C180.114 27.1852 158.09 3.36592 147.005 0.0873852C145.903 -0.23714 144.746 0.391107 144.421 1.49366C144.097 2.59621 144.725 3.75285 145.828 4.07738H145.824Z"
                        fill="#061041"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3480_4927">
                        <rect width="215" height="259" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  {t("Site Map")}
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
            <Col className="BodyCol">
              <div className="BodyText">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to={urlPage === "en" ? "/en" : "/"}
                  style={{ marginLeft: urlPage === "en" ? "20px" : "0px" }}
                >
                  {" "}
                  {t("Menu.Home")}
                </Link>
                <Link className="nav-link dropdown-toggle" role="button">
                  {t("Menu.About")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={
                    urlPage === "en"
                      ? "/en/about-university"
                      : "/about-university"
                  }
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M7.99984 1.33268C4.33317 1.33268 1.33317 4.33268 1.33317 7.99935C1.33317 11.666 4.33317 14.666 7.99984 14.666C11.6665 14.666 14.6665 11.666 14.6665 7.99935C14.6665 4.33268 11.6665 1.33268 7.99984 1.33268Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 10.666L8 7.33268"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00342 5.33398L7.99743 5.33398"
                      stroke="#09154E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("History and Facts")}

                  <div className="Submenu">
                    <ul>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/about-university/history-of-eelu"
                              : "/about-university/history-of-eelu"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14.6668 8.00065C14.6668 11.6807 11.6802 14.6673 8.00016 14.6673C4.32016 14.6673 1.3335 11.6807 1.3335 8.00065C1.3335 4.32065 4.32016 1.33398 8.00016 1.33398C11.6802 1.33398 14.6668 4.32065 14.6668 8.00065Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.4734 10.1192L8.40675 8.88586C8.04675 8.67253 7.75342 8.15919 7.75342 7.73919V5.00586"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("EELU History")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/about-university/vision-of-eelu"
                              : "/about-university/vision-of-eelu"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.9999 13.5138C10.3532 13.5138 12.5466 12.1271 14.0732 9.72714C14.6732 8.78714 14.6732 7.20714 14.0732 6.26714C12.5466 3.86714 10.3532 2.48047 7.9999 2.48047C5.64656 2.48047 3.45323 3.86714 1.92656 6.26714C1.32656 7.20714 1.32656 8.78714 1.92656 9.72714C3.45323 12.1271 5.64656 13.5138 7.9999 13.5138Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          {t("EELU Vision")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en//about-university/mission-of-eelu"
                              : "/about-university/mission-of-eelu"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14.6668 6.66732V10.0007C14.6668 13.334 13.3335 14.6673 10.0002 14.6673H6.00016C2.66683 14.6673 1.3335 13.334 1.3335 10.0007V6.00065C1.3335 2.66732 2.66683 1.33398 6.00016 1.33398H9.3335"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.6668 6.66732H12.0002C10.0002 6.66732 9.3335 6.00065 9.3335 4.00065V1.33398L14.6668 6.66732Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.6665 8.66602H8.6665"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.6665 11.334H7.33317"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("EELU Mission")}{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Link>
                <Link
                  className="dropdown-item"
                  to={
                    urlPage === "en"
                      ? "/en/board-of-trustees"
                      : "/board-of-trustees"
                  }
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                  role="button"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M7.99984 1.33268C4.33317 1.33268 1.33317 4.33268 1.33317 7.99935C1.33317 11.666 4.33317 14.666 7.99984 14.666C11.6665 14.666 14.6665 11.666 14.6665 7.99935C14.6665 4.33268 11.6665 1.33268 7.99984 1.33268Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 10.666L8 7.33268"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00342 5.33398L7.99743 5.33398"
                      stroke="#09154E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Board Of Trustees")}{" "}
                </Link>
                <Link
                  className="dropdown-item"
                  to={
                    urlPage === "en"
                      ? "/en/chairman-board-word"
                      : "chairman-board-word"
                  }
                  style={{
                    textAlign: urlPage === "en" ? "left" : "right",
                    whiteSpace: "pre",
                    lineHeight: "15px",
                    marginLeft: "-10px",
                  }}
                  role="button"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M8.00016 10.334C9.4735 10.334 10.6668 9.14065 10.6668 7.66732V4.00065C10.6668 2.52732 9.4735 1.33398 8.00016 1.33398C6.52683 1.33398 5.3335 2.52732 5.3335 4.00065V7.66732C5.3335 9.14065 6.52683 10.334 8.00016 10.334Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.8999 6.43359V7.56693C2.8999 10.3803 5.18657 12.6669 7.9999 12.6669C10.8132 12.6669 13.0999 10.3803 13.0999 7.56693V6.43359"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.07324 4.28609C7.67324 4.06609 8.32658 4.06609 8.92658 4.28609"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.4668 5.70086C7.82013 5.60753 8.1868 5.60753 8.54013 5.70086"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 12.666V14.666"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t(
                    "The Chairman of The University\n\nBoard of Treustees word"
                  )}
                </Link>
                <Link
                  className="dropdown-item"
                  to={
                    urlPage === "en"
                      ? "/en/university-president-word"
                      : "university-president-word"
                  }
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                    role="button"
                  >
                    <path
                      d="M8.00016 10.334C9.4735 10.334 10.6668 9.14065 10.6668 7.66732V4.00065C10.6668 2.52732 9.4735 1.33398 8.00016 1.33398C6.52683 1.33398 5.3335 2.52732 5.3335 4.00065V7.66732C5.3335 9.14065 6.52683 10.334 8.00016 10.334Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.8999 6.43359V7.56693C2.8999 10.3803 5.18657 12.6669 7.9999 12.6669C10.8132 12.6669 13.0999 10.3803 13.0999 7.56693V6.43359"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.07324 4.28609C7.67324 4.06609 8.32658 4.06609 8.92658 4.28609"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.4668 5.70086C7.82013 5.60753 8.1868 5.60753 8.54013 5.70086"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 12.666V14.666"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("University President’s Word")}{" "}
                </Link>
                <Link
                  className="dropdown-item"
                  to={urlPage === "en" ? "/en/contact" : "contact"}
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M14.6468 12.2207C14.6468 12.4607 14.5935 12.7073 14.4802 12.9473C14.3668 13.1873 14.2202 13.414 14.0268 13.6273C13.7002 13.9873 13.3402 14.2473 12.9335 14.414C12.5335 14.5807 12.1002 14.6673 11.6335 14.6673C10.9535 14.6673 10.2268 14.5073 9.46016 14.1807C8.6935 13.854 7.92683 13.414 7.16683 12.8607C6.40016 12.3007 5.6735 11.6807 4.98016 10.994C4.2935 10.3007 3.6735 9.57398 3.12016 8.81398C2.5735 8.05398 2.1335 7.29398 1.8135 6.54065C1.4935 5.78065 1.3335 5.05398 1.3335 4.36065C1.3335 3.90732 1.4135 3.47398 1.5735 3.07398C1.7335 2.66732 1.98683 2.29398 2.34016 1.96065C2.76683 1.54065 3.2335 1.33398 3.72683 1.33398C3.9135 1.33398 4.10016 1.37398 4.26683 1.45398C4.44016 1.53398 4.5935 1.65398 4.7135 1.82732L6.26016 4.00732C6.38016 4.17398 6.46683 4.32732 6.52683 4.47398C6.58683 4.61398 6.62016 4.75398 6.62016 4.88065C6.62016 5.04065 6.5735 5.20065 6.48016 5.35398C6.3935 5.50732 6.26683 5.66732 6.10683 5.82732L5.60016 6.35398C5.52683 6.42732 5.4935 6.51398 5.4935 6.62065C5.4935 6.67398 5.50016 6.72065 5.5135 6.77398C5.5335 6.82732 5.5535 6.86732 5.56683 6.90732C5.68683 7.12732 5.8935 7.41398 6.18683 7.76065C6.48683 8.10732 6.80683 8.46065 7.1535 8.81398C7.5135 9.16732 7.86016 9.49398 8.2135 9.79398C8.56016 10.0873 8.84683 10.2873 9.0735 10.4073C9.10683 10.4207 9.14683 10.4407 9.1935 10.4607C9.24683 10.4807 9.30016 10.4873 9.36016 10.4873C9.4735 10.4873 9.56016 10.4473 9.6335 10.374L10.1402 9.87398C10.3068 9.70732 10.4668 9.58065 10.6202 9.50065C10.7735 9.40732 10.9268 9.36065 11.0935 9.36065C11.2202 9.36065 11.3535 9.38732 11.5002 9.44732C11.6468 9.50732 11.8002 9.59398 11.9668 9.70732L14.1735 11.274C14.3468 11.394 14.4668 11.534 14.5402 11.7007C14.6068 11.8673 14.6468 12.034 14.6468 12.2207Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                    />
                  </svg>
                  {t("Contact Us")}{" "}
                </Link>
                <Link
                  className="dropdown-item"
                  to={urlPage === "en" ? "/en/FAQ" : "/FAQ"}
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M12.3136 11.2207L12.5736 13.3273C12.6402 13.8806 12.0469 14.2673 11.5736 13.9806L8.78022 12.3206C8.47356 12.3206 8.17356 12.3007 7.88023 12.2607C8.37356 11.6807 8.66689 10.9473 8.66689 10.154C8.66689 8.26064 7.02689 6.72734 5.00022 6.72734C4.22689 6.72734 3.51356 6.94732 2.92023 7.33398C2.90023 7.16732 2.89355 7.00064 2.89355 6.82731C2.89355 3.79398 5.52689 1.33398 8.78022 1.33398C12.0336 1.33398 14.6669 3.79398 14.6669 6.82731C14.6669 8.62731 13.7402 10.2207 12.3136 11.2207Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.66683 10.1532C8.66683 10.9465 8.3735 11.6799 7.88017 12.2599C7.22017 13.0599 6.1735 13.5732 5.00016 13.5732L3.26016 14.6065C2.96683 14.7865 2.59349 14.5399 2.63349 14.1999L2.80016 12.8866C1.90683 12.2666 1.3335 11.2732 1.3335 10.1532C1.3335 8.97986 1.96017 7.94654 2.92017 7.33321C3.5135 6.94654 4.22683 6.72656 5.00016 6.72656C7.02683 6.72656 8.66683 8.25986 8.66683 10.1532Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("Questions and Answers")}
                </Link>

                <Link
                  className="nav-link dropdown-toggle"
                  to={url}
                  role="button"
                >
                  {t("Menu.Academic")}
                </Link>

                <Link
                  className="dropdown-item"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.3335 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 8.66699V11.3337"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("Facilties")}

                  <div className="Submenu">
                    <ul>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/faculty-of-computers-and-information-technology"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            whiteSpace: "pre",
                            lineHeight: "15px",
                            marginLeft: "-10px",
                            marginBottom: "10px",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.3335 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 8.66699V11.3337"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Faculty of Computers and Information Technology")}

                          <br></br>
                          <br></br>
                          <div className="Submenu">
                            <ul>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={
                                    urlPage === "en"
                                      ? "/en/faculty-of-computers-and-information-technology/fci-quality"
                                      : "/faculty-of-computers-and-information-technology/fci-quality"
                                  }
                                  style={{
                                    textAlign:
                                      urlPage === "en" ? "left" : "right",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginRight:
                                        urlPage === "en" ? "6px" : " 0px",
                                      marginLeft:
                                        urlPage === "en" ? "0px" : "5px",
                                    }}
                                  >
                                    <path
                                      d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.99984 6.66667C6.73622 6.66667 7.33317 6.06971 7.33317 5.33333C7.33317 4.59695 6.73622 4 5.99984 4C5.26346 4 4.6665 4.59695 4.6665 5.33333C4.6665 6.06971 5.26346 6.66667 5.99984 6.66667Z"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M1.77979 12.633L5.06645 10.4264C5.59312 10.073 6.35312 10.113 6.82645 10.5197L7.04645 10.713C7.56645 11.1597 8.40645 11.1597 8.92645 10.713L11.6998 8.33305C12.2198 7.88638 13.0598 7.88638 13.5798 8.33305L14.6665 9.26638"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  {t("Quality")}{" "}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/Faculty-of-Business-Administration-and-Commercial-Studies"
                              : "/Faculty-of-Business-Administration-and-Commercial-Studies"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            whiteSpace: "pre",
                            lineHeight: "15px",
                            marginLeft: "-10px",
                            marginBottom: "10px",
                            marginTop: "-5px",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.3335 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 8.66699V11.3337"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t(
                            "Faculty of Commerical Studies and Business Administration"
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/faculty-of-educational-studies"
                              : "/faculty-of-educational-studies"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            marginTop: "-8px",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.3335 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 5.33301V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 8.66699V11.3337"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Faculty of Eductional Studies")}

                          <br></br>
                          <div className="Submenu">
                            <ul>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/faculty-of-educational-studies/postgraduate"
                                  style={{
                                    textAlign:
                                      urlPage === "en" ? "left" : "right",
                                    whiteSpace: "pre",
                                    lineHeight: "15px",
                                    marginLeft: "-10px",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginRight:
                                        urlPage === "en" ? "6px" : " 0px",
                                      marginLeft:
                                        urlPage === "en" ? "0px" : "5px",
                                    }}
                                  >
                                    <path
                                      d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9.3335 5.33301V8.66634"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12 5.33301V8.66634"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M4 8.66699V11.3337"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                                      stroke="#09154E"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  {t("Post Graduate")}
                                  <br />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Link>

                <Link
                  className="dropdown-item"
                  to=""
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.3335 8.66699H8.00016"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.3335 11.333H10.6668"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("Study System")}

                  <div className="Submenu">
                    <ul>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/how-to-apply"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M5.58643 8.00005L7.19309 9.61339L10.4131 6.38672"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.16643 1.63289C7.62643 1.23956 8.37976 1.23956 8.84643 1.63289L9.89976 2.53956C10.0998 2.71289 10.4731 2.85289 10.7398 2.85289H11.8731C12.5798 2.85289 13.1598 3.43289 13.1598 4.13956V5.27289C13.1598 5.53289 13.2998 5.91289 13.4731 6.11289L14.3798 7.16622C14.7731 7.62622 14.7731 8.37956 14.3798 8.84622L13.4731 9.89956C13.2998 10.0996 13.1598 10.4729 13.1598 10.7396V11.8729C13.1598 12.5796 12.5798 13.1596 11.8731 13.1596H10.7398C10.4798 13.1596 10.0998 13.2996 9.89976 13.4729L8.84643 14.3796C8.38643 14.7729 7.63309 14.7729 7.16643 14.3796L6.11309 13.4729C5.91309 13.2996 5.53976 13.1596 5.27309 13.1596H4.11976C3.41309 13.1596 2.83309 12.5796 2.83309 11.8729V10.7329C2.83309 10.4729 2.69309 10.0996 2.52643 9.89956L1.62643 8.83956C1.23976 8.37956 1.23976 7.63289 1.62643 7.17289L2.52643 6.11289C2.69309 5.91289 2.83309 5.53956 2.83309 5.27956V4.13289C2.83309 3.42622 3.41309 2.84622 4.11976 2.84622H5.27309C5.53309 2.84622 5.91309 2.70622 6.11309 2.53289L7.16643 1.63289Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Admission Policy")}{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/study-system"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14.6668 6.66634V9.99967C14.6668 13.333 13.3335 14.6663 10.0002 14.6663H6.00016C2.66683 14.6663 1.3335 13.333 1.3335 9.99967V5.99967C1.3335 2.66634 2.66683 1.33301 6.00016 1.33301H9.3335"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.6668 6.66634H12.0002C10.0002 6.66634 9.3335 5.99967 9.3335 3.99967V1.33301L14.6668 6.66634Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.6665 8.66699H8.6665"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.6665 11.333H7.33317"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Study System")}{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/transfer"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M14.6668 7.99967C14.6668 11.6797 11.6802 14.6663 8.00016 14.6663C4.32016 14.6663 2.0735 10.9597 2.0735 10.9597M2.0735 10.9597H5.08683M2.0735 10.9597V14.293M1.3335 7.99967C1.3335 4.31967 4.2935 1.33301 8.00016 1.33301C12.4468 1.33301 14.6668 5.03967 14.6668 5.03967M14.6668 5.03967V1.70634M14.6668 5.03967H11.7068"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Transfer Policy")}{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/assessment-examination-policy"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M7.3335 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V8.66634"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.6933 2.0135L5.43992 7.26684C5.23992 7.46684 5.03992 7.86017 4.99992 8.14684L4.71325 10.1535C4.60659 10.8802 5.11992 11.3868 5.84659 11.2868L7.85325 11.0002C8.13325 10.9602 8.52659 10.7602 8.73325 10.5602L13.9866 5.30684C14.8933 4.40017 15.3199 3.34684 13.9866 2.0135C12.6533 0.680168 11.5999 1.10684 10.6933 2.0135Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Assessments and examination Policy")}
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          to="/scholarships"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M6.70001 1.6862L2.68668 4.3062C1.40001 5.1462 1.40001 7.0262 2.68668 7.8662L6.70001 10.4862C7.42001 10.9595 8.60668 10.9595 9.32668 10.4862L13.32 7.8662C14.6 7.0262 14.6 5.15287 13.32 4.31287L9.32668 1.69287C8.60668 1.21287 7.42001 1.21287 6.70001 1.6862Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.75325 8.71973L3.74658 11.8464C3.74658 12.6931 4.39992 13.5997 5.19992 13.8664L7.32658 14.5731C7.69325 14.6931 8.29992 14.6931 8.67325 14.5731L10.7999 13.8664C11.5999 13.5997 12.2532 12.6931 12.2532 11.8464V8.75306"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.2666 10V6"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Scholarship")}{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Link>
                <Link
                  className="dropdown-item"
                  to={urlPage === "en" ? "/en/faculty-staff" : "/faculty-staff"}
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                  role="button"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M7.99984 1.33268C4.33317 1.33268 1.33317 4.33268 1.33317 7.99935C1.33317 11.666 4.33317 14.666 7.99984 14.666C11.6665 14.666 14.6665 11.666 14.6665 7.99935C14.6665 4.33268 11.6665 1.33268 7.99984 1.33268Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 10.666L8 7.33268"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00342 5.33398L7.99743 5.33398"
                      stroke="#09154E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Faculty Staff")}{" "}
                </Link>

                <Link
                  className="dropdown-item"
                  to="/eelu-learning-model"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M14.6668 6.66634V9.99967C14.6668 13.333 13.3335 14.6663 10.0002 14.6663H6.00016C2.66683 14.6663 1.3335 13.333 1.3335 9.99967V5.99967C1.3335 2.66634 2.66683 1.33301 6.00016 1.33301H9.3335"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6668 6.66634H12.0002C10.0002 6.66634 9.3335 5.99967 9.3335 3.99967V1.33301L14.6668 6.66634Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.6665 8.66699H8.6665"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.6665 11.333H7.33317"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("EELU Learning Model")}{" "}
                </Link>

                <Link
                  className="dropdown-item"
                  to="/international-relations"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("International Relations")}
                </Link>

                <Link
                  className="nav-link dropdown-toggle"
                  to={url}
                  role="button"
                >
                  {t("Menu.Media")}
                </Link>

                <Link
                  className="dropdown-item"
                  to={urlPage === "en" ? "/en/media" : "/media"}
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M4.83999 1.33301H11.1533C11.5867 1.33301 11.9733 1.34636 12.32 1.39303C14.1667 1.59969 14.6667 2.46635 14.6667 4.83968V9.05302C14.6667 11.4264 14.1667 12.293 12.32 12.4997C11.9733 12.5463 11.5933 12.5597 11.1533 12.5597H4.83999C4.40665 12.5597 4.01999 12.5463 3.67332 12.4997C1.82665 12.293 1.32666 11.4264 1.32666 9.05302V4.83968C1.32666 2.46635 1.82665 1.59969 3.67332 1.39303C4.01999 1.34636 4.40665 1.33301 4.83999 1.33301Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.05322 5.5459H11.5066"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.49316 9.40625H4.50649H11.5132"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.6665 14.667H11.3332"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.79647 5.53353H4.80246"
                      stroke="#09154E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.99618 5.53353H7.00216"
                      stroke="#09154E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("Media Coverage")}

                  <div className="Submenu">
                    <ul>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            urlPage === "en"
                              ? "/en/media/sub-gallery"
                              : "/media/sub-gallery"
                          }
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: urlPage === "en" ? "6px" : " 0px",
                              marginLeft: urlPage === "en" ? "0px" : "5px",
                            }}
                          >
                            <path
                              d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.99984 6.66667C6.73622 6.66667 7.33317 6.06971 7.33317 5.33333C7.33317 4.59695 6.73622 4 5.99984 4C5.26346 4 4.6665 4.59695 4.6665 5.33333C4.6665 6.06971 5.26346 6.66667 5.99984 6.66667Z"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.77979 12.633L5.06645 10.4264C5.59312 10.073 6.35312 10.113 6.82645 10.5197L7.04645 10.713C7.56645 11.1597 8.40645 11.1597 8.92645 10.713L11.6998 8.33305C12.2198 7.88638 13.0598 7.88638 13.5798 8.33305L14.6665 9.26638"
                              stroke="#09154E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          {t("Photo Gallery")}{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Link>
                {media.map((galleryy) => {
                  return (
                    <div className="cols">
                      <Link
                        to={`/gallery/${galleryy.params}`}
                        state={galleryy}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="card-body">
                          <h4
                            className="card-title"
                            style={{
                              textAlign: urlPage === "en" ? "left" : "right",
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: "2",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {urlPage == "en"
                              ? galleryy.titleEn
                              : galleryy.title}
                          </h4>
                        </div>
                      </Link>
                    </div>
                  );
                })}
                <br></br>
                <Link
                  className="dropdown-item"
                  to=""
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M14.6668 11.1605V3.11378C14.6668 2.31378 14.0135 1.72045 13.2202 1.78712H13.1802C11.7802 1.90712 9.6535 2.62045 8.46683 3.36712L8.3535 3.44045C8.16016 3.56045 7.84016 3.56045 7.64683 3.44045L7.48016 3.34045C6.2935 2.60045 4.1735 1.89378 2.7735 1.78045C1.98016 1.71378 1.3335 2.31378 1.3335 3.10712V11.1605C1.3335 11.8005 1.8535 12.4005 2.4935 12.4805L2.68683 12.5071C4.1335 12.7005 6.36683 13.4338 7.64683 14.1338L7.6735 14.1471C7.8535 14.2471 8.14016 14.2471 8.3135 14.1471C9.5935 13.4405 11.8335 12.7005 13.2868 12.5071L13.5068 12.4805C14.1468 12.4005 14.6668 11.8005 14.6668 11.1605Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 3.66016V13.6602"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.1665 5.66016H3.6665"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.6665 7.66016H3.6665"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("University News")}
                </Link>
                {allnews.map((newscard) => {
                  return (
                    <>
                      <Link
                        to={
                          urlPage === "en"
                            ? `/en/News/${newscard.params}`
                            : `/News/${newscard.params}`
                        }
                        state={newscard}
                      >
                        <h4
                          className="card-title"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                          }}
                        >
                          {" "}
                          {urlPage == "en" ? newscard.titleEn : newscard.title}
                        </h4>
                      </Link>
                    </>
                  );
                })}
                <Link
                  className="dropdown-item"
                  to="https://www.youtube.com/@egyptelu/featured"
                  target="_blank"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.06689 7.99999V7.01333C6.06689 5.73999 6.96689 5.22666 8.06689 5.85999L8.92023 6.35333L9.77356 6.84666C10.8736 7.47999 10.8736 8.51999 9.77356 9.15333L8.92023 9.64666L8.06689 10.14C6.96689 10.7733 6.06689 10.2533 6.06689 8.98666V7.99999Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Video")}
                </Link>

                <Link
                  className="nav-link dropdown-toggle"
                  to={url}
                  role="button"
                >
                  {t("Menu.University life")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={
                    urlPage === "en"
                      ? "/en/Learning-Center"
                      : "/Learning-Center"
                  }
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M7.99992 8.95297C9.14867 8.95297 10.0799 8.02172 10.0799 6.87297C10.0799 5.72422 9.14867 4.79297 7.99992 4.79297C6.85117 4.79297 5.91992 5.72422 5.91992 6.87297C5.91992 8.02172 6.85117 8.95297 7.99992 8.95297Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                    />
                    <path
                      d="M2.4133 5.65968C3.72664 -0.113657 12.28 -0.106991 13.5866 5.66634C14.3533 9.05301 12.2466 11.9197 10.4 13.693C9.05997 14.9863 6.93997 14.9863 5.5933 13.693C3.7533 11.9197 1.64664 9.04634 2.4133 5.65968Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                    />
                  </svg>

                  {t("Learning Centers")}
                </Link>

                <Link
                  className="dropdown-item"
                  to="/student-activities"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.88672 9.65984L6.47339 7.59984C6.70005 7.30651 7.12005 7.25318 7.41339 7.47984L8.63339 8.43984C8.92672 8.66651 9.34672 8.61318 9.57339 8.32651L11.1134 6.33984"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Student Activities")}{" "}
                </Link>

                <Link
                  className="nav-link dropdown-toggle"
                  to={url}
                  role="button"
                >
                  {t("Menu.Research")}
                </Link>
                <Link
                  className="dropdown-item"
                  to="/e-library"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M9.42015 7.56038C9.68015 6.46705 8.72681 5.51368 7.63348 5.77368C7.12015 5.90034 6.70015 6.32036 6.57348 6.83369C6.31348 7.92703 7.26681 8.88036 8.36015 8.62036C8.88015 8.49369 9.30015 8.07371 9.42015 7.56038Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.0068 11.4597C13.1468 10.393 13.8601 8.87966 13.8601 7.19299C13.8601 3.95299 11.2335 1.33301 8.00013 1.33301C4.7668 1.33301 2.14014 3.95966 2.14014 7.19299C2.14014 8.88633 2.86014 10.413 4.01347 11.4797"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.33318 9.69987C4.71985 9.04654 4.33984 8.16653 4.33984 7.1932C4.33984 5.1732 5.97985 3.5332 7.99985 3.5332C10.0198 3.5332 11.6599 5.1732 11.6599 7.1932C11.6599 8.16653 11.2798 9.03987 10.6665 9.69987"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.86665 11.1065L5.90665 12.2999C5.14665 13.2532 5.81998 14.6599 7.03998 14.6599H8.95331C10.1733 14.6599 10.8533 13.2465 10.0867 12.2999L9.12665 11.1065C8.55332 10.3799 7.44665 10.3799 6.86665 11.1065Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("The Virtual Library")}{" "}
                </Link>
                <Link
                  className="dropdown-item"
                  to={
                    urlPage === "en"
                      ? "/en/research-projects"
                      : "/research-projects"
                  }
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M12.2133 8.00045C13.9467 8.00045 14.6667 7.33378 14.0267 5.14711C13.5933 3.67378 12.3267 2.40711 10.8533 1.97378C8.66667 1.33378 8 2.05378 8 3.78711V5.70711C8 7.33378 8.66667 8.00045 10 8.00045H12.2133Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.3334 9.79952C12.7134 12.8862 9.75345 15.1262 6.38678 14.5795C3.86012 14.1729 1.82678 12.1395 1.41345 9.61285C0.87345 6.25952 3.10012 3.29952 6.17345 2.67285"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Research Projects")}{" "}
                </Link>

                <Link
                  className="nav-link dropdown-toggle"
                  to={url}
                  role="button"
                >
                  {t("Centres and Services")}
                </Link>

                <Link
                  className="dropdown-item"
                  to="/clc"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M6.66683 11.2999H4.14016C1.8935 11.2999 1.3335 10.7399 1.3335 8.49319V4.49319C1.3335 2.24652 1.8935 1.68652 4.14016 1.68652H11.1602C13.4068 1.68652 13.9668 2.24652 13.9668 4.49319"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.6665 14.3131V11.2998"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.3335 8.63379H6.66683"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.49316 14.3135H6.6665"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6668 8.5329V12.3396C14.6668 13.9196 14.2735 14.3129 12.6935 14.3129H10.3268C8.74685 14.3129 8.35352 13.9196 8.35352 12.3396V8.5329C8.35352 6.9529 8.74685 6.55957 10.3268 6.55957H12.6935C14.2735 6.55957 14.6668 6.9529 14.6668 8.5329Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.4962 12.1663H11.5022"
                      stroke="#09154E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Continuing Learning Center")}{" "}
                </Link>
                <Link
                  className="dropdown-item"
                  to="/specialized-centers"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.3335 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 8.66699V11.3337"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Specialized Centers.title")}{" "}
                </Link>
                <Link
                  className="dropdown-item"
                  to="/quality"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.3335 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 8.66699V11.3337"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("Qality Center")}{" "}
                </Link>

                <Link
                  className="dropdown-item"
                  to="/iso"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M8.66683 14.6663H3.3335C2.00016 14.6663 1.3335 13.9997 1.3335 12.6663V7.33301C1.3335 5.99967 2.00016 5.33301 3.3335 5.33301H6.66683V12.6663C6.66683 13.9997 7.3335 14.6663 8.66683 14.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.74015 2.66699C6.68682 2.86699 6.66683 3.08699 6.66683 3.33366V5.33366H3.3335V4.00033C3.3335 3.26699 3.9335 2.66699 4.66683 2.66699H6.74015Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.3335 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 5.33301V8.66634"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.3335 11.333H10.0002C9.6335 11.333 9.3335 11.633 9.3335 11.9997V14.6663H12.0002V11.9997C12.0002 11.633 11.7002 11.333 11.3335 11.333Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 8.66699V11.3337"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.6665 12.6663V3.33301C6.6665 1.99967 7.33317 1.33301 8.6665 1.33301H12.6665C13.9998 1.33301 14.6665 1.99967 14.6665 3.33301V12.6663C14.6665 13.9997 13.9998 14.6663 12.6665 14.6663H8.6665C7.33317 14.6663 6.6665 13.9997 6.6665 12.6663Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("ISO")}{" "}
                </Link>

                <Link
                  className="nav-link dropdown-toggle"
                  to={url}
                  role="button"
                >
                  {t("Menu.Services")}
                </Link>

                <Link
                  className="dropdown-item"
                  to={urlPage === "en" ? "/en/ssu-form" : "/ssu-form"}
                  role="button"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M12.0002 4.77301C11.9602 4.76634 11.9135 4.76634 11.8735 4.77301C10.9535 4.73968 10.2202 3.98634 10.2202 3.05301C10.2202 2.09968 10.9869 1.33301 11.9402 1.33301C12.8935 1.33301 13.6602 2.10634 13.6602 3.05301C13.6535 3.98634 12.9202 4.73968 12.0002 4.77301Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.3135 9.62645C12.2268 9.77978 13.2335 9.61978 13.9401 9.14645C14.8801 8.51978 14.8801 7.49312 13.9401 6.86645C13.2268 6.39312 12.2068 6.23311 11.2935 6.39311"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.97983 4.77301C4.01983 4.76634 4.06649 4.76634 4.10649 4.77301C5.02649 4.73968 5.75982 3.98634 5.75982 3.05301C5.75982 2.09968 4.99316 1.33301 4.03983 1.33301C3.08649 1.33301 2.31982 2.10634 2.31982 3.05301C2.32649 3.98634 3.05983 4.73968 3.97983 4.77301Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.66663 9.62645C3.75329 9.77978 2.74663 9.61978 2.03996 9.14645C1.09996 8.51978 1.09996 7.49312 2.03996 6.86645C2.75329 6.39312 3.77329 6.23311 4.68663 6.39311"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00021 9.75348C7.96021 9.74681 7.91355 9.74681 7.87355 9.75348C6.95355 9.72015 6.22021 8.96681 6.22021 8.03348C6.22021 7.08014 6.98688 6.31348 7.94021 6.31348C8.89355 6.31348 9.66021 7.08681 9.66021 8.03348C9.65355 8.96681 8.92021 9.72681 8.00021 9.75348Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.05998 11.8532C5.11998 12.4799 5.11998 13.5066 6.05998 14.1332C7.12665 14.8466 8.87331 14.8466 9.93998 14.1332C10.88 13.5066 10.88 12.4799 9.93998 11.8532C8.87998 11.1466 7.12665 11.1466 6.05998 11.8532Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {t("SSU")}{" "}
                </Link>
                <Link
                  className="dropdown-item"
                  to="/recruitment"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.3335 8.66699H8.00016"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.3335 11.333H10.6668"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("Recruitment")}
                </Link>

                <Link
                  className="dropdown-item"
                  to="https://www.eelu.edu.eg/component/sppagebuilder/?view=page&id=1065 "
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.3335 8.66699H8.00016"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.3335 11.333H10.6668"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("Employee complaints")}
                </Link>
                <Link
                  className="dropdown-item"
                  to="/e-Service"
                  style={{ textAlign: urlPage === "en" ? "left" : "right" }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: urlPage === "en" ? "6px" : " 0px",
                      marginLeft: urlPage === "en" ? "0px" : "5px",
                    }}
                  >
                    <path
                      d="M6.66683 11.2999H4.14016C1.8935 11.2999 1.3335 10.7399 1.3335 8.49319V4.49319C1.3335 2.24652 1.8935 1.68652 4.14016 1.68652H11.1602C13.4068 1.68652 13.9668 2.24652 13.9668 4.49319"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.6665 14.3131V11.2998"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.3335 8.63379H6.66683"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.49316 14.3135H6.6665"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6668 8.5329V12.3396C14.6668 13.9196 14.2735 14.3129 12.6935 14.3129H10.3268C8.74685 14.3129 8.35352 13.9196 8.35352 12.3396V8.5329C8.35352 6.9529 8.74685 6.55957 10.3268 6.55957H12.6935C14.2735 6.55957 14.6668 6.9529 14.6668 8.5329Z"
                      stroke="#09154E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.4962 12.1663H11.5022"
                      stroke="#09154E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {t("E-Service")}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
export default Sitemap;
