import React, { Fragment } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Main.css";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';


function HeaderStyle(props) {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  if(props.style == "LeadStyle"){
    return <>
      <div style={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", width: "100%", height: "50%", backgroundImage: `url(${props.backgroundImage})`
      }}>
        {/* <Navbar /> */}
        <div className="HeaderSubPage" style={{ direction: 'rtl', height: "70vmin" }}>
  
        {t("slogan")}      
          <p className="subtitle">   {t(props.title) == props.title ? t(props.title) : ""} </p>
  
        </div>
  
      </div>
  
  
    </>}else{
  return <>
    <div style={{
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover", width: "100%", height: "50%", backgroundImage: `url(${props.backgroundImage})`
    }}>
      {/* <Navbar /> */}
      <div className="HeaderSubPage" style={{ direction: 'rtl', height: "70vmin" }}>

        {t(props.title) == props.title ? t(props.title) : ""}
        <p className="subtitle"> {t("slogan")}</p>

      </div>

    </div>


  </>}
}
const DefaultHeader = (props) => {

  return (
    <Fragment>
      {HeaderStyle(props)}
      <div className="SubPagesHeaderDecoration"></div>
    </Fragment>
  );
};

export default DefaultHeader;
