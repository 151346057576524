import React, { useEffect, useState } from 'react'
import SsuFormCard from './SsuFormCard'
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../styles/componentsScss/SSU-Form.scss"


function SSu_Section(props) {
    const [t, i18n] = useTranslation();
    const [section2Visible, setSection2Visible] = useState(false);
    const [caseReason, setCaseReason] = useState([])
    const [selection, setSelection] = useState("")
    const [center, setCenter] = useState("Doki")
    const [copies, setCopies] = useState("1")
    const [personalMail, setpersonalMail] = useState("")
    const [caseReasonSelected, setCaseReasonSelected] = useState(false);
    const [subTypeSelected, setSubTypeSelected] = useState(false);
    const [subTypeValue, setSubTypeValue] = useState("")
    const [sectionId, setSectionId] = useState(1)
    const types = props.types
    const handleFromChild = props.handleFromChild

    const callBackFn = (id) => {

        let chossenSection = types.subType.find(section => id == section.id)
        setCaseReason(chossenSection.caseReason)
        setSection2Visible(true);
        setSectionId(chossenSection.id)
        setSubTypeSelected(true)
        setSubTypeValue(chossenSection.typeValue)

        ////////////////////////////////////////////
        // console.log(types.id)
        // if (chossenSection.caseReason === "") {
        //     setCaseReason("")
        //     console.log(chossenSection.caseReason)
        // } else {
        //     setSectionId(chossenSection.id)
        //     console.log(chossenSection.id)
        //     console.log(chossenSection.caseReason)
        //     setCaseReason(chossenSection.caseReason)
        //     setSubTypeSelected(true)
        // }
        // setSection2Visible(!section2Visible);
        // console.log(chossenSection.typeTitle)
        // console.log(chossenSection.typeValue)
        // setSubTypeValue(chossenSection.typeValue)
    }

    const handleSelection = (section) => {
        setSelection(section)
        setCaseReasonSelected(true)
        ///////////////////////////
        // if (caseReasonSelected) {
        //     setCaseReasonSelected(false)
        //     setSelection("")
        // } else {
        //     setSelection(section)
        //     setCaseReasonSelected(true)
        //     // console.log(selection)
        // }
    }

    useEffect(() => {
        // if (types.id !== 1) {
        //     setCenter("Doki")
        //     console.log(center)
        // }
        handleFromChild(subTypeValue, selection, subTypeSelected, caseReasonSelected, center, copies, personalMail)
    }, [selection, subTypeValue, subTypeSelected, caseReasonSelected, center, types, copies, personalMail])

    return (
        <div>

            {

                <>
                    <div className="div">
                        <br></br>
                        <br></br>
                        <div className="text-wrapper" >
                            <LazyLoadImage

                                alt="Heroicons square"
                                src="https://generation-sessions.s3.amazonaws.com/9041e72c92a90c0459a94bbe0336a1c0/img/heroicons-square-3-stack-3d@2x.png"

                            />
                            <span className="ssudepartmentTitle1"> {t('Select Section')} </span>
                        </div>
                    </div>

                    <br></br>
                </>


            }
            <div className="row row-cols-1 row-cols-sm-3 g-5 " >

                {
                    // console.log(types.subType)
                    types.subType &&
                    types.subType.map(section => {
                        return <SsuFormCard title={section.typeTitle} icon={section.typeIcon} callBackFn={() => callBackFn(section.id)} id={section.id} selected={section.id === sectionId ? subTypeSelected : ""} />
                    })

                }



            </div>
            <br></br>
            <div className="text-wrapper" >
                <LazyLoadImage

                    alt="Heroicons square"
                    src="https://generation-sessions.s3.amazonaws.com/9041e72c92a90c0459a94bbe0336a1c0/img/heroicons-square-3-stack-3d@2x.png"

                />
                <span className="ssudepartmentTitle"> {t('Select Request')} </span>
            </div>

            <br></br>
            <div className="row row-cols-1 row-cols-sm-3 g-5 " >


                {

                    section2Visible && caseReason && (
                        <>
                            {caseReason.map(section => (
                                <SsuFormCard
                                    title={section.caseReasonTitle}
                                    icon={section.caseReasonIcon}
                                    callBackFn={() => handleSelection(section.caseReasonTitle)}
                                    selected={section.caseReasonTitle === selection ? caseReasonSelected : ""}
                                />

                            ))
                            }
                            <br />



                        </>

                    )
                }

            </div>
            <div>
                {
                    types.id !== 3 && types.id !== 2 ?
                        <div>
                            < label className="form-label" > {t('Number of Copies')}
                                <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                    value={copies}
                                    onChange={(e) => setCopies(e.target.value)}
                                    style={{
                                        width: '80%',
                                        marginTop: '20px',
                                        borderColor: 'rgb(20, 43, 123)',
                                        height: '45px',
                                        fontSize: '20px'
                                    }}>
                                    <option selected></option>

                                    <option value="1" >1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>


                                </select>
                            </label>

                            <label className="form-label"> {t('Delivery Center')}
                                <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                    value={center}
                                    onChange={(e) => setCenter(e.target.value)}
                                    style={{
                                        width: '80%',
                                        marginTop: '20px',
                                        borderColor: 'rgb(20, 43, 123)',
                                        height: '45px',
                                        fontSize: '20px'
                                    }}>
                                    <option selected></option>
                                    <option value="Doki" >الدقي</option>
                                    <option value="Alexandria">الاسكندرية</option>
                                    <option value="Alfayoum">الفيوم</option>
                                    <option value="Alsadat">السادات</option>
                                    <option value="Assuit">أسيوط</option>
                                    <option value="Aswan">أسوان</option>
                                    <option value="Beni Suef">بني سويف</option>
                                    <option value="Hurghada">الغردقة</option>
                                    <option value="Ismailia">الاسماعيلية</option>
                                    <option value="Menoufia">المنوفية</option>
                                    <option value="Qena">قنا</option>
                                    <option value="Tanta">طنطا</option>
                                    <option value="Sohag">سوهاج</option>
                                </select>
                            </label>
                        </div>
                        : null
                }
                {
                    types.id === 3 && sectionId === 2 || types.id === 1 && sectionId !== 1 ?
                        <>
                            <label style={{ color: 'rgb(17 38 113)' }}>البريد الالكتروني الشخصي</label>
                            <input
                                value={personalMail}
                                onChange={(e) => setpersonalMail(e.target.value)}
                                type="text"
                                id="subject2"
                                name="email"
                                placeholder="قم بكتابة الايميل الشخصي"
                                style={{
                                    width: '80%',
                                    // maxWidth: '100%',
                                    marginTop: '12px',
                                    borderColor: 'rgb(20, 43, 123)',
                                    border: '1px solid',
                                    borderRadius: '8px'
                                }}
                            ></input>

                        </>
                        : null

                }
            </div>
        </div >
    )
}


export default SSu_Section