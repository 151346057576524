import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Main.scss";


import ExploreMoreButton from "./ExploreMoreButton";
import { useTranslation } from 'react-i18next';


const Centers = () => {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  return (
    <Fragment>

      <div className="BodyDivLight">

        <Container>

          <div className="BodyTitle">
            <Row >
              <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} >
                <br />
                <svg width="50" height="35" viewBox="0 0 215 259" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_3480_4927)">
                    <path d="M124.566 163.976C124.158 162.903 122.956 162.362 121.882 162.77C120.809 163.177 120.268 164.38 120.675 165.453L154.662 254.839H62.3182L77.8801 213.907H121.716C122.864 213.907 123.796 212.975 123.796 211.827C123.796 210.679 122.864 209.747 121.716 209.747H79.4613L85.5363 193.766H110.718C111.867 193.766 112.799 192.834 112.799 191.686C112.799 190.537 111.867 189.605 110.718 189.605H87.1216L96.3048 165.453C96.7126 164.38 96.1716 163.177 95.0981 162.77C94.0246 162.362 92.8221 162.903 92.4143 163.976L57.3542 256.183C57.1129 256.824 57.1961 257.539 57.5872 258.105C57.9742 258.667 58.615 259.004 59.3015 259.004H157.679C158.361 259.004 159.002 258.667 159.393 258.105C159.78 257.543 159.867 256.824 159.626 256.183L124.566 163.976Z" fill="#061041" />
                    <path d="M212.92 142.45H115.304C114.022 139.5 112.445 137.07 110.619 135.073C110.606 135.061 112.82 133.759 113.032 133.655C113.91 133.218 114.817 132.843 115.741 132.523C117.742 131.828 119.827 131.383 121.924 131.083C127.025 130.355 132.222 130.422 137.357 130.588C143.569 130.788 149.777 131.179 155.985 131.47C162.193 131.761 168.243 131.99 174.381 132.061C180.502 132.128 186.627 132.04 192.735 131.641C196.059 131.424 199.376 131.108 202.679 130.676C203.82 130.526 204.618 129.477 204.469 128.337C204.319 127.197 203.266 126.394 202.13 126.548C196.259 127.33 190.334 127.692 184.413 127.842C178.263 127.996 172.109 127.909 165.959 127.709C159.809 127.509 154.021 127.214 148.059 126.91C145.034 126.91 141.988 126.594 138.963 126.469C135.938 126.344 133.2 126.274 130.321 126.315C125.086 126.39 119.748 126.827 114.755 128.508C112.162 129.377 109.687 130.601 107.498 132.244C107.456 132.273 104.24 130.193 103.895 130.018C102.501 129.315 101.04 128.745 99.5505 128.283C96.4589 127.322 93.2384 126.823 90.0136 126.557C81.4879 125.858 72.9205 126.611 64.3989 127.039C55.8773 127.468 48.1296 127.826 39.9866 127.905C30.9366 127.988 21.8491 127.738 12.8698 126.548C11.7297 126.399 10.6853 127.197 10.5313 128.337C10.3774 129.477 11.1804 130.522 12.3205 130.676C28.4026 132.814 44.7676 132.165 60.9203 131.383C68.822 131 76.7486 130.393 84.6668 130.505C90.463 130.588 96.617 131.017 101.901 133.63C102.118 133.738 104.39 135.065 104.377 135.082C102.551 137.079 100.974 139.508 99.692 142.458H2.08047C0.932051 142.458 0 143.39 0 144.539C0 145.687 0.932051 146.619 2.08047 146.619H101.094C101.956 146.619 102.725 146.091 103.037 145.283C104.215 142.217 105.709 139.766 107.502 137.807C109.3 139.771 110.789 142.221 111.967 145.283C112.275 146.086 113.049 146.619 113.91 146.619H212.924C214.072 146.619 215.004 145.687 215.004 144.539C215.004 143.39 214.072 142.458 212.924 142.458L212.92 142.45Z" fill="#061041" />
                    <path d="M84.24 114.387C84.9391 115.061 85.7796 115.535 86.6451 115.219C87.5646 114.878 87.9599 113.725 87.7685 112.76C87.5771 111.795 86.9571 110.988 86.358 110.21C84.2317 107.464 82.1554 104.676 80.129 101.855C74.5742 94.1165 69.2981 85.862 67.3175 76.5464C64.151 61.664 70.4923 45.2589 82.6797 36.1472C94.8671 27.0356 112.21 25.5336 125.908 32.1614C133.734 35.9475 140.367 42.2092 144.457 49.8855C148.543 57.5576 150.041 66.611 148.485 75.1651C146.754 84.6679 141.486 93.1222 135.902 101.006C133.734 104.069 131.504 107.089 129.211 110.06C128.379 111.142 127.489 112.394 127.718 113.738C127.888 114.741 129.033 115.406 129.998 115.227C131.101 115.019 131.7 113.754 132.332 112.927C134.321 110.33 136.273 107.709 138.182 105.055C144.291 96.563 150.099 87.5096 152.271 77.2787C155.584 61.7057 149.442 44.5849 137.196 34.4081C124.951 24.2313 107.196 21.294 92.2082 26.6611C81.8267 30.3807 72.8141 37.903 67.5047 47.568C62.1954 57.233 60.6933 68.97 63.6143 79.6003C65.7322 87.3098 70.0138 94.2289 74.4826 100.857C77.3578 105.117 80.3329 109.307 83.4078 113.426C83.6533 113.75 83.9321 114.091 84.2442 114.387H84.24Z" fill="#061041" />
                    <path d="M108.489 51.2211C115.954 51.2211 122.025 57.2914 122.025 64.7555C122.025 65.9038 122.957 66.8358 124.105 66.8358C125.254 66.8358 126.186 65.9038 126.186 64.7555C126.186 54.9989 118.247 47.0605 108.489 47.0605C98.7321 47.0605 90.793 54.9989 90.793 64.7555C90.793 74.512 98.7321 82.4504 108.489 82.4504C109.638 82.4504 110.57 81.5184 110.57 80.3701C110.57 79.2218 109.638 78.2898 108.489 78.2898C101.025 78.2898 94.9539 72.2195 94.9539 64.7555C94.9539 57.2914 101.025 51.2211 108.489 51.2211Z" fill="#061041" />
                    <path d="M139.834 18.5357C149.683 22.9126 158.625 33.78 160.631 43.8112C160.83 44.8014 161.696 45.4837 162.669 45.4837C162.807 45.4837 162.944 45.4713 163.081 45.4421C164.209 45.2175 164.937 44.1191 164.712 42.9957C162.428 31.5749 152.675 19.6882 141.528 14.7329C140.475 14.2669 139.247 14.7412 138.781 15.7897C138.315 16.8382 138.786 18.0697 139.838 18.5357H139.834Z" fill="#061041" />
                    <path d="M145.824 4.07738C155.664 6.98978 176.248 29.8813 178.133 45.3544C178.262 46.4112 179.161 47.1851 180.197 47.1851C180.28 47.1851 180.368 47.1809 180.451 47.1685C181.591 47.0312 182.402 45.991 182.265 44.851C180.114 27.1852 158.09 3.36592 147.005 0.0873852C145.903 -0.23714 144.746 0.391107 144.421 1.49366C144.097 2.59621 144.725 3.75285 145.828 4.07738H145.824Z" fill="#061041" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3480_4927">
                      <rect width="215" height="259" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {t("Centers Title")}
              </Col>
              <Col xs={{ span: 4 }} md={{ span: 3 }} lg={{ span: 4 }} >

                {/* <ExploreMoreButton buutonText={t('More')}
                  style="exploreMoreStyle"
                  link={urlPage == "en" ? '/en/Learning-Center' : `/Learning-Center`} /> */}

              </Col>
            </Row>
          </div><br /><br />
          <Row>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 1 }} lg={{ span: 7, order: 1 }} >
              <div className="MainPageBodyContainer" >
                <Row >
                  <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 1 }} lg={{ span: 5, order: 1 }} >
                    <Row style={{
                      marginTop: '25px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />

                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284" >
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Headquarter' : '/Learning-Center/Headquarter'}
                          rel="noopener noreferrer"
                        >
                          {t("Learning Center1.Headquarter.Center-Name")}
                        </a>


                      </Col>
                    </Row>


                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""

                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Ainshams-Center' : '/Learning-Center/Ainshams-Center'}
                          rel="noopener noreferrer"
                        >
                          {t("Learning Center1.Ainshams Center.Center-Name")}
                        </a>


                      </Col>
                    </Row>


                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""

                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Assiut-Center' : '/Learning-Center/Assiut-Center'}
                          rel="noopener noreferrer"
                        >
                          {t("Learning Center1.Assiut Center.Center-Name")}
                        </a>


                      </Col>
                    </Row>


                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""

                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Alexandria-Center' : '/Learning-Center/Alexandria-Center'}
                        >
                          {t("Learning Center1.Alexandria Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>


                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""

                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Menoufia-Center' : '/Learning-Center/Menoufia-Center'}
                        >
                          {t("Learning Center1.Menoufia Center.Center-Name")}
                        </a>
                      </Col>
                    </Row>

                    <Row style={{
                      marginTop: '20px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""

                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Sohag-Center' : '/Learning-Center/Sohag-Center'}
                        >
                          {t("Learning Center1.Sohag Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>

                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""

                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Beni-Suef-Center' : '/Learning-Center/Beni-Suef-Center'}
                        >
                          {t("Learning Center1.Beni Suef Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>


                  </Col>
                  <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 2 }} lg={{ span: 5, order: 2 }} >
                    <Row style={{
                      marginTop: '25px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/South-Vally-Qena-Center' : '/Learning-Center/South-Vally-Qena-Center'}
                        >
                          {t("Learning Center1.South Vally Qena Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>
                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/South-Valley-Hurghada-Center' : '/Learning-Center/South-Valley-Hurghada-Center'}
                        >
                          {t("Learning Center1.South Valley Hurghada Center.Center-Name")}
                        </a>
                      </Col>
                    </Row>

                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >

                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Aswan-Center' : '/Learning-Center/Aswan-Center'}
                        >
                          {t("Learning Center1.Aswan Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>

                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Ismailia-Center' : '/Learning-Center/Ismailia-Center'}
                        >
                          {t("Learning Center1.Ismailia Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>
                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Sadat-Center' : '/Learning-Center/Sadat-Center'}
                        >
                          {t("Learning Center1.Sadat Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>


                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Fayoum-Center' : '/Learning-Center/Fayoum-Center'}
                        >
                          {t("Learning Center1.Fayoum Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>



                    <Row style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      marginLeft: urlPage === 'en' ? "10px" : ""
                    }}><br /><br />
                      <Col xs={{ span: 1, order: 1 }} md={{ span: 1, order: 1 }} lg={{ span: 1, order: 1 }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21.979 27.284">
                          <path id="Vector" d="M8.851,26.085a26.163,26.163,0,0,1-3.573-3.075A17.254,17.254,0,0,1,0,11.117,11.182,11.182,0,0,1,3.219,3.256a10.9,10.9,0,0,1,15.541,0,11.182,11.182,0,0,1,3.219,7.861A17.246,17.246,0,0,1,16.7,23.011a26.162,26.162,0,0,1-3.574,3.075q-.742.531-1.524,1l-.095.055-.037.02a1,1,0,0,1-.963,0l-.037-.022-.093-.054q-.782-.469-1.525-1Zm2.138-10.927a3.973,3.973,0,0,0,2.826-1.184,4.076,4.076,0,0,0,0-5.717,3.964,3.964,0,0,0-5.651,0,4.076,4.076,0,0,0,0,5.717A3.973,3.973,0,0,0,10.989,15.159Z" transform="translate(0 0)" fill="#1f4385" fill-rule="evenodd" />
                        </svg>
                      </Col>
                      <Col xs={{ span: 10, order: 1 }} md={{ span: 10, order: 1 }} lg={{ span: 10, order: 1 }} >
                        <a
                          className="TitleLink"
                          href={urlPage === "en" ? '/en/Learning-Center/Tanta-Center' : '/Learning-Center/Tanta-Center'}
                        >
                          {t("Learning Center1.Tanta Center.Center-Name")}
                        </a>

                      </Col>
                    </Row>

                  </Col>
                </Row>
              </div>
              <br /><br />

            </Col>
            <Col xs={{ span: 11, order: 2 }} md={{ span: 4, order: 2 }} lg={{ span: 4, order: 2 }} >

              {/* <LazyLoadImage src={require('./Map.gif')} alt="EELU Member of" width="70%" height="auto" /> */}

              {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/ " viewBox="0 0 4801 4801">
                <image width="100%" href={require('./Map.gif')}></image>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/South-Valley-Hurghada-Center' : event => window.location.href = `/Learning-Center/South-Valley-Hurghada-Center`} >
                  <rect x="3080" y="2116" fill="#fff" opacity="0" width="560" height="540"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Beni-Suef-Center' : event => window.location.href = `/Learning-Center/Beni-Suef-Center`} >
                  <rect x="1952" y="2176" fill="#fff" opacity="0" width="496" height="476"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Ainshams-Center' : event => window.location.href = `/Learning-Center/Ainshams-Center`} >
                  <rect x="2280" y="1636" fill="#fff" opacity="0" width="540" height="444"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Fayoum-Center' : event => window.location.href = `/Learning-Center/Fayoum-Center`} >
                  <rect x="1548" y="2012" fill="#fff" opacity="0" width="308" height="420"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Tanta-Center' : event => window.location.href = `/Learning-Center/Tanta-Center`} >
                  <rect x="1784" y="895" fill="#fff" opacity="0" width="388" height="448"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Alexandria-Center' : event => window.location.href = `/Learning-Center/Alexandria-Center`} >
                  <rect x="1449" y="430" fill="#fff" opacity="0" width="520" height="452"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Sadat-Center' : event => window.location.href = `/Learning-Center/Sadat-Center`} >
                  <rect x="1384" y="1246" fill="#fff" opacity="0" width="392" height="568"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Menoufia-Center' : event => window.location.href = `/Learning-Center/Menoufia-Center`} >
                  <rect x="2180" y="1158" fill="#fff" opacity="0" width="412" height="428"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Headquarter' : event => window.location.href = `/Learning-Center/Headquarter`} >
                  <rect x="1855" y="1596" fill="#fff" opacity="0" width="296" height="464"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Ismailia-Center' : event => window.location.href = `/Learning-Center/Ismailia-Center`} >
                  <rect x="2657" y="654" fill="#fff" opacity="0" width="568" height="516"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Assiut-Center' : event => window.location.href = `/Learning-Center/Assiut-Center`} >
                  <rect x="2444" y="2596" fill="#fff" opacity="0" width="400" height="420"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Sohag-Center' : event => window.location.href = `/Learning-Center/Sohag-Center`} >
                  <rect x="2472" y="3056" fill="#fff" opacity="0" width="416" height="428"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/South-Vally-Qena-Center' : event => window.location.href = `/Learning-Center/South-Vally-Qena-Center`} >
                  <rect x="2888" y="3272" fill="#fff" opacity="0" width="572" height="468"></rect>
                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Aswan-Center' : event => window.location.href = `/Learning-Center/Aswan-Center`} >
                  <rect x="2548" y="3748" fill="#fff" opacity="0" width="496" height="520"></rect>

                </Link>

              </svg> */}









              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1154 1002" style={{ direction: "rtl" }}>
                <image width="1154" height="1002" href={require('./Map.png')}></image>


                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Alexandria-Center' : event => window.location.href = `/Learning-Center/Alexandria-Center`} >

                  <rect x="374" y="7" fill="#fff" opacity="0" width="194" height="82"></rect>
                  <svg class="path" x="390" y="10">
                    <text class="growsandstays" x="190" y="40" >{t("Learning Center1.Alexandria Center.Center-Name")}</text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Headquarter' : event => window.location.href = `/Learning-Center/Headquarter`} >
                  <svg class="path" x="200" y="275">
                    <text class="growsandstays" x="250" y="50" > {t("Learning Center1.Headquarter.Center-Name")}</text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.885 50.1374L285.055 50.2381L285.125 50.2784C285.395 50.4295 285.695 50.5 286.005 50.5C286.315 50.5 286.615 50.4194 286.885 50.2784L286.955 50.2381L287.125 50.1374C288.076 49.5633 288.996 48.9488 289.896 48.3042C292.227 46.6321 294.407 44.7385 296.398 42.6636C301.109 37.7482 306 30.365 306 20.8666C306 15.4678 303.889 10.2804 300.139 6.46293C296.388 2.64545 291.306 0.5 286.005 0.5C280.704 0.5 275.612 2.64545 271.861 6.46293C268.111 10.2905 266 15.4678 266 20.8767C266 30.3751 270.901 37.7583 275.602 42.6736C277.603 44.7486 279.773 46.6322 282.104 48.3143C283.004 48.9589 283.934 49.5733 284.885 50.1475V50.1374ZM286.005 28.28C287.935 28.28 289.786 27.5044 291.146 26.1144C292.507 24.7244 293.277 22.8409 293.277 20.8767C293.277 18.9126 292.507 17.029 291.146 15.639C289.786 14.249 287.935 13.4633 286.005 13.4633C284.075 13.4633 282.224 14.249 280.864 15.639C279.503 17.029 278.733 18.9126 278.733 20.8767C278.733 22.8409 279.503 24.7244 280.864 26.1144C282.224 27.5044 284.075 28.28 286.005 28.28Z" />
                    <path d="M286 68.5C280.484 68.5 276 64.0161 276 58.5C276 52.9839 280.484 48.5 286 48.5C291.516 48.5 296 52.9839 296 58.5C296 64.0161 291.516 68.5 286 68.5ZM286 51.0688C281.906 51.0688 278.569 54.406 278.569 58.5C278.569 62.594 281.906 65.9312 286 65.9312C290.094 65.9312 293.431 62.594 293.431 58.5C293.431 54.406 290.094 51.0688 286 51.0688Z" />
                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Sadat-Center' : event => window.location.href = `/Learning-Center/Sadat-Center`} >

                  <svg class="path" x="180" y="190">
                    <text class="growsandstays" x="250" y="50" >
                      {t("Learning Center1.Sadat Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.885 50.1374L285.055 50.2381L285.125 50.2784C285.395 50.4295 285.695 50.5 286.005 50.5C286.315 50.5 286.615 50.4194 286.885 50.2784L286.955 50.2381L287.125 50.1374C288.076 49.5633 288.996 48.9488 289.896 48.3042C292.227 46.6321 294.407 44.7385 296.398 42.6636C301.109 37.7482 306 30.365 306 20.8666C306 15.4678 303.889 10.2804 300.139 6.46293C296.388 2.64545 291.306 0.5 286.005 0.5C280.704 0.5 275.612 2.64545 271.861 6.46293C268.111 10.2905 266 15.4678 266 20.8767C266 30.3751 270.901 37.7583 275.602 42.6736C277.603 44.7486 279.773 46.6322 282.104 48.3143C283.004 48.9589 283.934 49.5733 284.885 50.1475V50.1374ZM286.005 28.28C287.935 28.28 289.786 27.5044 291.146 26.1144C292.507 24.7244 293.277 22.8409 293.277 20.8767C293.277 18.9126 292.507 17.029 291.146 15.639C289.786 14.249 287.935 13.4633 286.005 13.4633C284.075 13.4633 282.224 14.249 280.864 15.639C279.503 17.029 278.733 18.9126 278.733 20.8767C278.733 22.8409 279.503 24.7244 280.864 26.1144C282.224 27.5044 284.075 28.28 286.005 28.28Z" />
                    <path d="M286 68.5C280.484 68.5 276 64.0161 276 58.5C276 52.9839 280.484 48.5 286 48.5C291.516 48.5 296 52.9839 296 58.5C296 64.0161 291.516 68.5 286 68.5ZM286 51.0688C281.906 51.0688 278.569 54.406 278.569 58.5C278.569 62.594 281.906 65.9312 286 65.9312C290.094 65.9312 293.431 62.594 293.431 58.5C293.431 54.406 290.094 51.0688 286 51.0688Z" />
                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Menoufia-Center' : event => window.location.href = `/Learning-Center/Menoufia-Center`} >

                  <svg class="path" x="515" y="188">
                    <text class="growsandstays" x="180" y="40" >
                      {t("Learning Center1.Menoufia Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Tanta-Center' : event => window.location.href = `/Learning-Center/Tanta-Center`} >

                  <svg class="path" x="466" y="95">
                    <text class="growsandstays" x="120" y="40" >
                      {t("Learning Center1.Tanta Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Assiut-Center' : event => window.location.href = `/Learning-Center/Assiut-Center`} >

                  <svg class="path" x="500" y="568">
                    <text class="growsandstays" x="140" y="40" >  {t("Learning Center1.Assiut Center.Center-Name")}</text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Ainshams-Center' : event => window.location.href = `/Learning-Center/Ainshams-Center`} >

                  <svg class="path" x="504" y="395">
                    <text class="growsandstays" x="180" y="40" >
                      {t("Learning Center1.Beni Suef Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Ainshams-Center' : event => window.location.href = `/Learning-Center/Ainshams-Center`} >

                  <svg class="path" x="567" y="272">
                    <text class="growsandstays" x="200" y="40" >  {t("Learning Center1.Ainshams Center.Center-Name")}</text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/South-Vally-Qena-Center' : event => window.location.href = `/Learning-Center/South-Vally-Qena-Center`} >

                  <svg class="path" x="613" y="709">
                    <text class="growsandstays" x="100" y="40" >
                      {t("Learning Center1.South Vally Qena Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Ismailia-Center' : event => window.location.href = `/Learning-Center/Ismailia-Center`} >

                  <svg class="path" x="685" y="63">
                    <text class="growsandstays" x="200" y="40" >
                      {t("Learning Center1.Ismailia Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8847 49.6374L19.0548 49.7381L19.1248 49.7784C19.3948 49.9295 19.6949 50 20.005 50C20.3151 50 20.6152 49.9194 20.8852 49.7784L20.9552 49.7381L21.1253 49.6374C22.0755 49.0633 22.9957 48.4488 23.896 47.8042C26.2266 46.1321 28.4071 44.2385 30.3976 42.1636C35.1088 37.2482 40 29.865 40 20.3666C40 14.9678 37.8895 9.78042 34.1385 5.96293C30.3876 2.14545 25.3063 0 20.005 0C14.7037 0 9.6124 2.14545 5.86146 5.96293C2.11053 9.79049 0 14.9678 0 20.3767C0 29.8751 4.90123 37.2583 9.6024 42.1736C11.6029 44.2486 13.7734 46.1322 16.104 47.8143C17.0042 48.4589 17.9345 49.0733 18.8847 49.6475V49.6374ZM20.005 27.78C21.9355 27.78 23.7859 27.0044 25.1463 25.6144C26.5066 24.2244 27.2768 22.3409 27.2768 20.3767C27.2768 18.4126 26.5066 16.529 25.1463 15.139C23.7859 13.749 21.9355 12.9633 20.005 12.9633C18.0745 12.9633 16.2241 13.749 14.8637 15.139C13.5034 16.529 12.7332 18.4126 12.7332 20.3767C12.7332 22.3409 13.5034 24.2244 14.8637 25.6144C16.2241 27.0044 18.0745 27.78 20.005 27.78Z" />
                    <path d="M20 68C14.4839 68 10 63.5161 10 58C10 52.4839 14.4839 48 20 48C25.5161 48 30 52.4839 30 58C30 63.5161 25.5161 68 20 68ZM20 50.5688C15.906 50.5688 12.5688 53.906 12.5688 58C12.5688 62.094 15.906 65.4312 20 65.4312C24.094 65.4312 27.4312 62.094 27.4312 58C27.4312 53.906 24.094 50.5688 20 50.5688Z" />

                  </svg>
                </Link>


                {/* <svg class="path" x="230" y="480">
                    <text class="growsandstays" x="250" y="50" >المنيا</text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.885 50.1374L285.055 50.2381L285.125 50.2784C285.395 50.4295 285.695 50.5 286.005 50.5C286.315 50.5 286.615 50.4194 286.885 50.2784L286.955 50.2381L287.125 50.1374C288.076 49.5633 288.996 48.9488 289.896 48.3042C292.227 46.6321 294.407 44.7385 296.398 42.6636C301.109 37.7482 306 30.365 306 20.8666C306 15.4678 303.889 10.2804 300.139 6.46293C296.388 2.64545 291.306 0.5 286.005 0.5C280.704 0.5 275.612 2.64545 271.861 6.46293C268.111 10.2905 266 15.4678 266 20.8767C266 30.3751 270.901 37.7583 275.602 42.6736C277.603 44.7486 279.773 46.6322 282.104 48.3143C283.004 48.9589 283.934 49.5733 284.885 50.1475V50.1374ZM286.005 28.28C287.935 28.28 289.786 27.5044 291.146 26.1144C292.507 24.7244 293.277 22.8409 293.277 20.8767C293.277 18.9126 292.507 17.029 291.146 15.639C289.786 14.249 287.935 13.4633 286.005 13.4633C284.075 13.4633 282.224 14.249 280.864 15.639C279.503 17.029 278.733 18.9126 278.733 20.8767C278.733 22.8409 279.503 24.7244 280.864 26.1144C282.224 27.5044 284.075 28.28 286.005 28.28Z" />
                    <path d="M286 68.5C280.484 68.5 276 64.0161 276 58.5C276 52.9839 280.484 48.5 286 48.5C291.516 48.5 296 52.9839 296 58.5C296 64.0161 291.516 68.5 286 68.5ZM286 51.0688C281.906 51.0688 278.569 54.406 278.569 58.5C278.569 62.594 281.906 65.9312 286 65.9312C290.094 65.9312 293.431 62.594 293.431 58.5C293.431 54.406 290.094 51.0688 286 51.0688Z" />
                  </svg> */}


                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Headquarter' : event => window.location.href = `/Learning-Center/Headquarter`} >

                  <svg class="path" x="550" y="508">
                    <text class="growsandstays" x="250" y="50" >
                      {t("Learning Center1.South Valley Hurghada Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.885 50.1374L285.055 50.2381L285.125 50.2784C285.395 50.4295 285.695 50.5 286.005 50.5C286.315 50.5 286.615 50.4194 286.885 50.2784L286.955 50.2381L287.125 50.1374C288.076 49.5633 288.996 48.9488 289.896 48.3042C292.227 46.6321 294.407 44.7385 296.398 42.6636C301.109 37.7482 306 30.365 306 20.8666C306 15.4678 303.889 10.2804 300.139 6.46293C296.388 2.64545 291.306 0.5 286.005 0.5C280.704 0.5 275.612 2.64545 271.861 6.46293C268.111 10.2905 266 15.4678 266 20.8767C266 30.3751 270.901 37.7583 275.602 42.6736C277.603 44.7486 279.773 46.6322 282.104 48.3143C283.004 48.9589 283.934 49.5733 284.885 50.1475V50.1374ZM286.005 28.28C287.935 28.28 289.786 27.5044 291.146 26.1144C292.507 24.7244 293.277 22.8409 293.277 20.8767C293.277 18.9126 292.507 17.029 291.146 15.639C289.786 14.249 287.935 13.4633 286.005 13.4633C284.075 13.4633 282.224 14.249 280.864 15.639C279.503 17.029 278.733 18.9126 278.733 20.8767C278.733 22.8409 279.503 24.7244 280.864 26.1144C282.224 27.5044 284.075 28.28 286.005 28.28Z" />
                    <path d="M286 68.5C280.484 68.5 276 64.0161 276 58.5C276 52.9839 280.484 48.5 286 48.5C291.516 48.5 296 52.9839 296 58.5C296 64.0161 291.516 68.5 286 68.5ZM286 51.0688C281.906 51.0688 278.569 54.406 278.569 58.5C278.569 62.594 281.906 65.9312 286 65.9312C290.094 65.9312 293.431 62.594 293.431 58.5C293.431 54.406 290.094 51.0688 286 51.0688Z" />
                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Fayoum-Center' : event => window.location.href = `/Learning-Center/Fayoum-Center`} >

                  <svg class="path" x="150" y="361">
                    <text class="growsandstays" x="250" y="50" >
                      {t("Learning Center1.Fayoum Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.885 50.1374L285.055 50.2381L285.125 50.2784C285.395 50.4295 285.695 50.5 286.005 50.5C286.315 50.5 286.615 50.4194 286.885 50.2784L286.955 50.2381L287.125 50.1374C288.076 49.5633 288.996 48.9488 289.896 48.3042C292.227 46.6321 294.407 44.7385 296.398 42.6636C301.109 37.7482 306 30.365 306 20.8666C306 15.4678 303.889 10.2804 300.139 6.46293C296.388 2.64545 291.306 0.5 286.005 0.5C280.704 0.5 275.612 2.64545 271.861 6.46293C268.111 10.2905 266 15.4678 266 20.8767C266 30.3751 270.901 37.7583 275.602 42.6736C277.603 44.7486 279.773 46.6322 282.104 48.3143C283.004 48.9589 283.934 49.5733 284.885 50.1475V50.1374ZM286.005 28.28C287.935 28.28 289.786 27.5044 291.146 26.1144C292.507 24.7244 293.277 22.8409 293.277 20.8767C293.277 18.9126 292.507 17.029 291.146 15.639C289.786 14.249 287.935 13.4633 286.005 13.4633C284.075 13.4633 282.224 14.249 280.864 15.639C279.503 17.029 278.733 18.9126 278.733 20.8767C278.733 22.8409 279.503 24.7244 280.864 26.1144C282.224 27.5044 284.075 28.28 286.005 28.28Z" />
                    <path d="M286 68.5C280.484 68.5 276 64.0161 276 58.5C276 52.9839 280.484 48.5 286 48.5C291.516 48.5 296 52.9839 296 58.5C296 64.0161 291.516 68.5 286 68.5ZM286 51.0688C281.906 51.0688 278.569 54.406 278.569 58.5C278.569 62.594 281.906 65.9312 286 65.9312C290.094 65.9312 293.431 62.594 293.431 58.5C293.431 54.406 290.094 51.0688 286 51.0688Z" />
                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Sohag-Center' : event => window.location.href = `/Learning-Center/Sohag-Center`} >

                  <svg class="path" x="260" y="659">
                    <text class="growsandstays" x="250" y="50" >
                      {t("Learning Center1.Sohag Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.885 50.1374L285.055 50.2381L285.125 50.2784C285.395 50.4295 285.695 50.5 286.005 50.5C286.315 50.5 286.615 50.4194 286.885 50.2784L286.955 50.2381L287.125 50.1374C288.076 49.5633 288.996 48.9488 289.896 48.3042C292.227 46.6321 294.407 44.7385 296.398 42.6636C301.109 37.7482 306 30.365 306 20.8666C306 15.4678 303.889 10.2804 300.139 6.46293C296.388 2.64545 291.306 0.5 286.005 0.5C280.704 0.5 275.612 2.64545 271.861 6.46293C268.111 10.2905 266 15.4678 266 20.8767C266 30.3751 270.901 37.7583 275.602 42.6736C277.603 44.7486 279.773 46.6322 282.104 48.3143C283.004 48.9589 283.934 49.5733 284.885 50.1475V50.1374ZM286.005 28.28C287.935 28.28 289.786 27.5044 291.146 26.1144C292.507 24.7244 293.277 22.8409 293.277 20.8767C293.277 18.9126 292.507 17.029 291.146 15.639C289.786 14.249 287.935 13.4633 286.005 13.4633C284.075 13.4633 282.224 14.249 280.864 15.639C279.503 17.029 278.733 18.9126 278.733 20.8767C278.733 22.8409 279.503 24.7244 280.864 26.1144C282.224 27.5044 284.075 28.28 286.005 28.28Z" />
                    <path d="M286 68.5C280.484 68.5 276 64.0161 276 58.5C276 52.9839 280.484 48.5 286 48.5C291.516 48.5 296 52.9839 296 58.5C296 64.0161 291.516 68.5 286 68.5ZM286 51.0688C281.906 51.0688 278.569 54.406 278.569 58.5C278.569 62.594 281.906 65.9312 286 65.9312C290.094 65.9312 293.431 62.594 293.431 58.5C293.431 54.406 290.094 51.0688 286 51.0688Z" />
                  </svg>

                </Link>
                <Link aria-label="Read more about Seminole tax hike" className="TitleLink" onClick={urlPage == "en" ? event => window.location = '/en/Learning-Center/Aswan-Center' : event => window.location.href = `/Learning-Center/Aswan-Center`} >

                  <svg class="path" x="300" y="820">
                    <text class="growsandstays" x="250" y="50" >
                      {t("Learning Center1.Aswan Center.Center-Name")}
                    </text>

                    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.885 50.1374L285.055 50.2381L285.125 50.2784C285.395 50.4295 285.695 50.5 286.005 50.5C286.315 50.5 286.615 50.4194 286.885 50.2784L286.955 50.2381L287.125 50.1374C288.076 49.5633 288.996 48.9488 289.896 48.3042C292.227 46.6321 294.407 44.7385 296.398 42.6636C301.109 37.7482 306 30.365 306 20.8666C306 15.4678 303.889 10.2804 300.139 6.46293C296.388 2.64545 291.306 0.5 286.005 0.5C280.704 0.5 275.612 2.64545 271.861 6.46293C268.111 10.2905 266 15.4678 266 20.8767C266 30.3751 270.901 37.7583 275.602 42.6736C277.603 44.7486 279.773 46.6322 282.104 48.3143C283.004 48.9589 283.934 49.5733 284.885 50.1475V50.1374ZM286.005 28.28C287.935 28.28 289.786 27.5044 291.146 26.1144C292.507 24.7244 293.277 22.8409 293.277 20.8767C293.277 18.9126 292.507 17.029 291.146 15.639C289.786 14.249 287.935 13.4633 286.005 13.4633C284.075 13.4633 282.224 14.249 280.864 15.639C279.503 17.029 278.733 18.9126 278.733 20.8767C278.733 22.8409 279.503 24.7244 280.864 26.1144C282.224 27.5044 284.075 28.28 286.005 28.28Z" />
                    <path d="M286 68.5C280.484 68.5 276 64.0161 276 58.5C276 52.9839 280.484 48.5 286 48.5C291.516 48.5 296 52.9839 296 58.5C296 64.0161 291.516 68.5 286 68.5ZM286 51.0688C281.906 51.0688 278.569 54.406 278.569 58.5C278.569 62.594 281.906 65.9312 286 65.9312C290.094 65.9312 293.431 62.594 293.431 58.5C293.431 54.406 290.094 51.0688 286 51.0688Z" />
                  </svg>

                </Link>





              </svg>
            </Col>
          </Row>

        </Container>

        <br /><br /><br />
      </div>
    </Fragment>
  );
};

export default Centers;
