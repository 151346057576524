import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import Routess from './routes/routes';
import reportWebVitals from './reportWebVitals';
import Footer from './components/Footer';
import LanguageToggle from './components/LanguageToggle';
import Navbar from './components/Navbar';
import './i18n';
import Box from './pages/EELU-Advertising';
import HomePage from './components/Homepage';



const items = [
  { name: 'Home', url: '/' },
  {
    name: 'Tutorials',
    children: [
      { name: 'Beginner', url: '/tutorials/beginner' },
      { name: 'Intermediate', url: '/tutorials/intermediate' },
      { name: 'Advanced', url: '/tutorials/advanced' }
    ]
  }
];
const url = window.location.href.split("/");
//console.log(url[3]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/* <Navbar /> */}

    <LanguageToggle />

    {/* <Box /> */}
    <Routess />
    <Footer />
  </BrowserRouter>
);

reportWebVitals();
