import React from 'react';
import MessengerCustomerChat from "react-messenger-customer-chat"
import { Link } from 'react-router-dom';
import "../styles/componentsScss/Messenger.scss"

const FacebookMessenger = () => {
  //failed try again later
  return (
    // <MessengerCustomerChat
    //   pageId="106094165920443"
    //   appId="808164887833302"
    // />
    <Link to='http://m.me/152204768173436' style={{

    }}
      target='_blank'
      className='messengerStyle'
    >
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 0C10.482 0 0 9.906 0 23.28C0 30.276 2.868 36.324 7.536 40.5C7.926 40.848 8.166 41.34 8.178 41.868L8.31 46.14C8.31961 46.4543 8.40628 46.7615 8.5624 47.0345C8.71852 47.3075 8.93929 47.5379 9.20533 47.7056C9.47137 47.8733 9.77453 47.9731 10.0882 47.9961C10.4018 48.0192 10.7163 47.9649 11.004 47.838L15.768 45.738C16.17 45.558 16.626 45.528 17.052 45.642C19.242 46.242 21.57 46.566 24 46.566C37.518 46.566 48 36.66 48 23.286C48 9.912 37.518 0 24 0Z" fill="url(#paint0_radial_5406_1598)" />
        <path d="M9.58827 30.0902L16.6383 18.9062C16.9035 18.4851 17.2531 18.1236 17.6651 17.8445C18.0771 17.5653 18.5424 17.3746 19.0318 17.2844C19.5212 17.1942 20.024 17.2065 20.5084 17.3204C20.9928 17.4343 21.4484 17.6474 21.8463 17.9462L27.4563 22.1522C27.7067 22.3397 28.0114 22.4405 28.3243 22.4394C28.6371 22.4384 28.9411 22.3354 29.1903 22.1462L36.7623 16.3982C37.7703 15.6302 39.0903 16.8422 38.4183 17.9162L31.3623 29.0942C31.0971 29.5153 30.7474 29.8768 30.3355 30.1559C29.9235 30.435 29.4581 30.6257 28.9687 30.7159C28.4793 30.8061 27.9766 30.7939 27.4921 30.68C27.0077 30.5661 26.5522 30.353 26.1543 30.0542L20.5443 25.8482C20.2938 25.6607 19.9891 25.5598 19.6763 25.5609C19.3634 25.562 19.0594 25.665 18.8103 25.8542L11.2383 31.6022C10.2303 32.3702 8.91027 31.1642 9.58827 30.0902Z" fill="white" />
        <defs>
          <radialGradient id="paint0_radial_5406_1598" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.04 48) scale(52.8 52.8)">
            <stop stop-color="#0099FF" />
            <stop offset="0.6" stop-color="#A033FF" />
            <stop offset="0.9" stop-color="#FF5280" />
            <stop offset="1" stop-color="#FF7061" />
          </radialGradient>
        </defs>
      </svg>
    </Link>
    // <MessengerCustomerChat
    //   pageId={152204768173436}
    //   appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
    //   version={16.0}
    //   xfbml={true}
    // />
  );
};

export default FacebookMessenger;